import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Config } from '@mitel/cloudlink-sdk';
import { RouteUtils } from './app/shared/utils/route-utils';

if (environment.production) {
  enableProdMode();
}

// force to dev cloud based on DNS
const location = window.location.href;
if (!environment.production || RouteUtils.isDevHost(location)) {
  console.log('main()...using dev cloud');
  Config.cloud = 'dev';
}
else {
  Config.cloud = 'public';
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
