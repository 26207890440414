import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss']
})
export class FooterNavigationComponent implements OnInit {

  currentYear: number;
  version: string = environment.appVersion;

  constructor() { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
