import { RouterModule, Routes } from '@angular/router';
import { GetStartedComponent } from './get-started.component';
import { SignupComponent } from './signup/signup.component';
import { LocationComponent } from './location/location.component';
import { NumberComponent } from './number/number.component';
import { InviteComponent } from './invite/invite.component';
import { CardComponent } from './card/card.component';
import { VerificationComponent } from './verification/verification.component';
import { AuthGuard } from '../../guards/auth.guard';
import { LocationResolver } from '../../services/location.resolver';
import { InvitedUsersResolver } from '../../services/invited-users.resolver';
import { SiteResolver } from '../../services/site.resolver';

const getStartedRoutes: Routes = [
  {
    path: 'getStarted',
    component: GetStartedComponent,
    canActivateChild: [ AuthGuard ],
    children: [
      { path: '', redirectTo: 'signup', pathMatch: 'full' },
      { path: 'signup', component: SignupComponent },
      { path: 'verification', component: VerificationComponent },
      { path: 'location', component: LocationComponent, resolve: { location : LocationResolver } },
      { path: 'number', component: NumberComponent , resolve: { site: SiteResolver} },
      { path: 'invite', component: InviteComponent, resolve: { invitedUsers : InvitedUsersResolver } },
      { path: 'card', component: CardComponent , resolve: { site: SiteResolver} }
    ]
  }
];

export const GetStartedRouting = RouterModule.forChild(getStartedRoutes);
