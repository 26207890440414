import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseDataComponent } from '../shared/base-data-component';
import { catchError, debounceTime, distinct, pairwise, switchMap, takeWhile } from 'rxjs/operators';
import { StorageItemName, StorageService } from '../../../services/storage-service.service';
import { AdminService } from '../../../services/admin.service';
import { User, UserPostRequest } from '@mitel/cloudlink-sdk/admin';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { spinnerPipe } from '../../../shared/utils/spinner-pipe.operator';
import { SpinnerService } from '../../../services/spinner.service';
import { duplicateEmailValidator } from '../../../shared/custom-validators';
import { PageName } from '../../../shared/models/page-name.enum';
import { UserType } from '../../../shared/models/user-type-enum';
import { AccountTagKeys } from '../../../shared/models/user-tag-keys.enum';

export const MAX_NUMBER_OF_INVITES = 10;

@Component({
  selector: 'app-invite' ,
  templateUrl: './invite.component.html' ,
  styleUrls: [ './invite.component.scss' ]
})

export class InviteComponent extends BaseDataComponent implements OnInit , AfterViewInit {

  constructor(
    private fb: FormBuilder ,
    private router: Router ,
    private route: ActivatedRoute ,
    private storageService: StorageService ,
    private adminService: AdminService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  addUserForm: FormGroup;
  attemptedSubmit = false;
  errors: any[] = [];
  maxInvites = false;
  failedEmailIndexes = [];
  emailsOfInvitedUsers = [];
  storedBusinessNumber: string;
  existingInvitedUserEmails = [];

  // Unsaved phone browser's native alert
  @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
      if ( this.storedBusinessNumber !== null) {
        $event.returnValue = true;
      }
    }

  ngOnInit() {
    super.ngOnInit();
    this.storedBusinessNumber = this.storageService.getLocalStorageItem(StorageItemName.BUSINESS_NUMBER);
    console.log('stored business number -> invite page', this.storedBusinessNumber);
    this.addUserForm = this.fb.group({
      users: this.fb.array([
        this.fb.group({
          name: [ '' ] ,
          email: [ '' ]
        })
      ])
    });
    this.setInvitedUsers();

    this.adminService.createAccountTag(AccountTagKeys.LAST_PAGE, PageName.INVITE)
      .pipe(
        takeWhile(() => this.active),
      ).subscribe((createdTag) => console.log(createdTag, 'account tag created'));
  }

  ngAfterViewInit(): void {
    this.addUserForm.valueChanges
      .pipe(
        takeWhile(() => this.active && this.attemptedSubmit),
        debounceTime(200),
        pairwise(),
        distinct(([first , second ]) => first !== second)
      )
      .subscribe((value) => {
        this.checkErrors();
      });
    this.addUserForm.setValidators([duplicateEmailValidator()]);
  }

  setInvitedUsers(): void {
    const { snapshot } = this.route;

    if (snapshot && snapshot.data && snapshot.data['invitedUsers']) {
      const invitedUsers = snapshot.data['invitedUsers'];
      if (invitedUsers && invitedUsers.length) {
        for (let i = 0; i < invitedUsers.length - 1 ; i++) {
          this.addUser();
        }

        const users = <FormArray>this.addUserForm.get('users');
        users.controls.forEach(
          (user: FormGroup , index: number) => {
            user.controls[ 'name' ].setValue(invitedUsers[index].name);
            user.controls[ 'email' ].setValue(invitedUsers[index].email);
            this.existingInvitedUserEmails.push(invitedUsers[index].email);
          });
      }
    }

  }

  addUser(): void {

    const users = <FormArray>this.addUserForm.get('users');
    if (users.length === MAX_NUMBER_OF_INVITES) {
      this.maxInvites = true;
      return;
    }

    users.push(
      this.fb.group({
        name: [ '' ] ,
        email: [ '' ]
      })
    );
  }

  checkErrors(): void {

    this.errors = [];
    const users = <FormArray>this.addUserForm.get('users');
    users.controls.forEach(
      (user: FormGroup , index: number) => {

        const name = user.controls[ 'name' ].value ? user.controls[ 'name' ].value.trim() : '';
        const email = user.controls[ 'email' ].value ? user.controls[ 'email' ].value.trim().toLowerCase() : '';

        if (!name && !email) {
          this.clearErrors(user);
        } else if ( user.dirty ) {
          if ( name && !email) {
            this.errors.push({formErrorIndex: index});
            user.controls[ 'email' ].setErrors({required: true});
            this.updateValueAndValidity(user, 'name');
          } else if ( !name && email) {
              this.errors.push({formErrorIndex: index});
              user.controls[ 'name' ].setErrors({required: true});
          }
            user.controls[ 'email' ].setValidators([Validators.email]);
            this.updateValueAndValidity(user, 'email');
        }
      }
    );
  }

  updateValueAndValidity(user: FormGroup, controlName: string): void {
    user.controls[controlName].updateValueAndValidity();
  }

  clearErrors(user: FormGroup): void {
    user.controls[ 'name' ].reset('');
    user.controls[ 'name' ].markAsUntouched();
    user.controls[ 'email' ].reset('');
    user.controls[ 'email' ].markAsUntouched();
  }

  continue(): void {

    this.attemptedSubmit = true;
    this.checkErrors();
    if (this.errors.length || this.addUserForm.invalid) {
      return;
    }

    if (this.hasInvitees()) {

      const users = <FormArray>this.addUserForm.get('users');
      let newEmailCount = 0;

      users.controls.forEach((user: FormGroup) => {
        if (this.existingInvitedUserEmails.indexOf(user.controls[ 'email' ].value) === -1) {
          newEmailCount++;
        }
      });

      if ( newEmailCount > 0) {
        this.buildCreateUserTaskArray()
          .pipe(
            takeWhile(() => this.active) ,
            switchMap(this.addUsersToAccount) ,
            switchMap((users: User[]) => this.sendWelcomeEmails(users)) ,
            catchError((error) => {
              return throwError(error);
            }) ,
            spinnerPipe(this.spinnerService)
          )
          .subscribe(() => {
            if (this.addUserForm.valid) {
              this.router.navigate([ 'card' ] , {relativeTo: this.route.parent});
            }
          });
      } else {
          this.router.navigate([ 'card' ] , {relativeTo: this.route.parent});
      }
    } else {
      this.router.navigate([ 'card' ] , {relativeTo: this.route.parent});
    }
  }

  hasInvitees(): boolean {

    const users = <FormArray>this.addUserForm.get('users');
    let numberOfInvites = 0;

    users.controls.forEach(
      (user: FormGroup) => {
        if (user.controls[ 'name' ].value && user.controls[ 'email' ].value) {
          numberOfInvites++;
        }
      }
    );

    return Boolean(numberOfInvites);
  }

  buildCreateUserTaskArray(): Observable<any[]> {

    const users = <FormArray>this.addUserForm.get('users');
    const accountId = this.storageService.getLocalStorageItem(StorageItemName.USER).accountId;
    const tasks = [];

    users.controls.forEach((user: FormGroup) => {

      if (this.emailsOfInvitedUsers.indexOf(user.controls[ 'email' ].value) === -1
          && this.existingInvitedUserEmails.indexOf(user.controls[ 'email' ].value) === -1
      ) {
        const body: UserPostRequest = {
          email: user.controls[ 'email' ].value.toLowerCase(),
          name: user.controls[ 'name' ].value
        };

        const params = {
          accountId: accountId ,
          body: body
        };
        tasks.push(this.adminService.createUserInAccount(params).pipe(catchError((err) => {
          if (err.statusCode === 400) {
            return of('invalidEmail');
          } else {
            return of(null);
          }
        })));
      }

    });

    return of(tasks);
  }

  addUsersToAccount(tasks: any[]): Observable<any[]> {
    return forkJoin(...tasks);
  }

  sendWelcomeEmails(users: User[]): Observable<any[]> {

    const tasks = [];
    this.storageService.setLocalStorageItem(StorageItemName.USERS, users.length);
    users.forEach((user: User) => {
      if (user && user.createdOn) {
        const params = {
          accountId: user.accountId ,
          userId: user.userId ,
          body: {
            email: user.email
          }
        };
        this.emailsOfInvitedUsers.push(user.email);
        tasks.push(this.adminService.sendWelcomeToUserByAccountId(params, UserType.user));
      }
    });

    this.findInvalidEmailIndexes();
    return forkJoin(...tasks);
  }

  findInvalidEmailIndexes(): void {
    this.failedEmailIndexes.length = 0;
    const users = <FormArray>this.addUserForm.get('users');
    users.controls.forEach((user: FormGroup, index: number) => {
      const email = user.controls[ 'email' ].value.trim().toLowerCase();
      if (email && this.emailsOfInvitedUsers.indexOf(email) === -1 && this.existingInvitedUserEmails.indexOf(email) === -1) {
        this.failedEmailIndexes.push(index);
      }
    });

    if (this.failedEmailIndexes.length) {
      this.showInvalidEmailError();
    }

  }

  showInvalidEmailError(): void {
    const users = <FormArray>this.addUserForm.get('users');
    this.failedEmailIndexes.forEach((val) => {
      users.controls[ val ].get('email').setErrors({'email': true});
    });
  }

  skipStep() {
      this.router.navigate([ 'card' ] , {relativeTo: this.route.parent});
  }

}
