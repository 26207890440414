import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { environment } from '../../../environments/environment';

export class TextUtils {

  public static normalizePhoneNumber(phone: string | number, countryCode: CountryCode = 'US') {
    const phoneNumber = parsePhoneNumberFromString(String(phone), countryCode);
    if (phoneNumber && phoneNumber.isValid()) {
      const countryMeta = environment.countries.find((c) => c.code === countryCode);
      if (countryMeta.e164NumberFormat) {
        return phoneNumber.formatInternational();
      }
      else {
        return phoneNumber.formatNational();
      }
    }
    return null;
  }

  public static convertToTitleCase(str: string): string {
    return str.toLowerCase().replace(/[^\s]+/g, (word) => word.replace(/^./, (first) => first.toUpperCase()));
  }

  public static isAlpha(value: string): boolean {
    return value && /[a-z]/i.test(value);
  }

  public static formatPhoneNumberToE164(phone: string): string {
    const formattedNumber = parsePhoneNumberFromString(phone, 'US');
    if (formattedNumber && formattedNumber.isValid()) {
      return String(formattedNumber.number);
    }
    return null;
  }

  public static toQueryString(params: Object): string {
    return Object.keys(params)
      .map(k => k !== undefined && params[k] !== undefined ? `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}` : '')
      .join('&');
  }

  public static getPathFromUrl(url: string): string {
    return url.split(/[?#]/)[0];
  }

  public static stripUnderscore(str: string): string {
    return str.replace(/_/g, ' ');
  }
}
