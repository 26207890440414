import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AnalyticsService } from '../../services/analytics.service';

@Directive({
  selector: '[registerInput]'
})
export class RegisterInputDirective {

  constructor(private control: NgControl, private analyticsSrv: AnalyticsService) {}

  @HostListener('focusout', ['$event.target'])
  onFocusout(target) {
    // Provide form control if we want to track stats based on value
    if (this.control.control) {
      this.analyticsSrv.recordEvent('Input interaction', { input: target.getAttribute('formControlName') });
    }
  }
}
