import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacebookSsoService {

  constructor(private http: HttpClient) { }

  getAccountsFromToken(userToken: string): Observable<any> {
    return this.http.get(`https://graph.facebook.com/me/accounts?access_token=${userToken}`);
  }

  getLocationFromPageId(pageToken: string): Observable<any> {
    return this.http.get(`https://graph.facebook.com/me?fields=location&access_token=${pageToken}`);
  }
}
