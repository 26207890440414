import { DigitalSignature } from '@mitel/cloudlink-sdk/billing';
import { Location, User } from '@mitel/cloudlink-sdk/admin';
import { environment } from '../../../environments/environment';

export interface HpmParams {
    tenantId: string;
    id: string;
    token: string;
    signature: string;
    style: DigitalSignature.StyleEnum;
    key: string;
    submitEnabled: boolean;
    locale?: string;
    url: string;
    paymentGateway?: string;
}

export interface HpmFields {
    creditCardAddress1?: string;
    creditCardAddress2?: string;
    creditCardCity?: string;
    creditCardCountry: string;
    creditCardState: string;
    creditCardPostalCode?: string;
    creditCardHolderName?: string;
    email?: string;
}

export interface ILocation {
    companyName: string;
    country: string;
    address: string;
    address2?: string;
    city: string;
    stateOrProvince: string;
    zipOrPostalCode: string;
    timezone?: string;
}


export class ZuoraUtils {

    /**
     * Create hosted payment method parameters from a digital signature (from {@link BillingServiceBase#generateSignature}).
     * 
     * @param signature is the signature to convert to hosted payment method parameters.
     * @param locale is the locale to use, or uses the browser window global.
     * 
     * @see https://knowledgecenter.zuora.com/CB_Billing/LA_Hosted_Payment_Pages/B_Payment_Pages_2.0/F_Generate_the_Digital_Signature_for_Payment_Pages_2.0
     * @see https://knowledgecenter.zuora.com/CB_Billing/LA_Hosted_Payment_Pages/B_Payment_Pages_2.0/J_Client_Parameters_for_Payment_Pages_2.0
     */
    public static getZuoraHpmParamsFromDigitalSignature(signature: DigitalSignature, locale: string): HpmParams {
        return {
            tenantId: signature.tenantId,
            id: signature.pageId,
            token: signature.token,
            signature: signature.signature,
            style: signature.style,
            key: signature.key,
            submitEnabled: signature.submitEnabled,
            locale,
            url: signature.uri,
            paymentGateway: signature.paymentGateway
        };
    }

    private static getZuoraCountry(country: string): string {

        const c = environment.countries.find(c => c.code === country);
        if (c) {
            return c.codeAlpha3; // zuora wants iso alpha 3 code
        }
        else {
            throw new Error(`Country ${country} is not currently supported`);
        }
    }

    private static getZuoraStateOrProvince(stateOrProvince: string): string {
        switch (stateOrProvince) {
            case 'AB': return 'Alberta';
            case 'BC': return 'British Columbia';
            case 'MB': return 'Manitoba';
            case 'NB': return 'New Brunswick';
            case 'NL': return 'Newfoundland and Labrador';
            case 'NT': return 'Northwest Territories';
            case 'NS': return 'Nova Scotia';
            case 'NU': return 'Nunavut';
            case 'ON': return 'Ontario';
            case 'PE': return 'Prince Edward Island';
            case 'SK': return 'Saskatchewan';
            case 'YT': return 'Yukon';
            case 'AL': return 'Alabama';
            case 'AK': return 'Alaska';
            case 'AZ': return 'Arizona';
            case 'AR': return 'Arkansas';
            case 'CA': return 'California';
            case 'CO': return 'Colorado';
            case 'CT': return 'Connecticut';
            case 'DE': return 'Delaware';
            case 'FL': return 'Florida';
            case 'GA': return 'Georgia';
            case 'HI': return 'Hawaii';
            case 'ID': return 'Idaho';
            case 'IL': return 'Illinois';
            case 'IN': return 'Indiana';
            case 'IA': return 'Iowa';
            case 'KS': return 'Kansas';
            case 'KY': return 'Kentucky';
            case 'LA': return 'Louisiana';
            case 'ME': return 'Maine';
            case 'MD': return 'Maryland';
            case 'MA': return 'Massachusetts';
            case 'MI': return 'Michigan';
            case 'MN': return 'Minnesota';
            case 'MS': return 'Mississippi';
            case 'MO': return 'Missouri';
            case 'MT': return 'Montana';
            case 'NE': return 'Nebraska';
            case 'NV': return 'Nevada';
            case 'NH': return 'New Hampshire';
            case 'NJ': return 'New Jersey';
            case 'NM': return 'New Mexico';
            case 'NY': return 'New York';
            case 'NC': return 'North Carolina';
            case 'ND': return 'North Dakota';
            case 'OH': return 'Ohio';
            case 'OK': return 'Oklahoma';
            case 'OR': return 'Oregon';
            case 'PA': return 'Pennsylvania';
            case 'RI': return 'Rhode Island';
            case 'SC': return 'South Carolina';
            case 'SD': return 'South Dakota';
            case 'TN': return 'Tennessee';
            case 'TX': return 'Texas';
            case 'UT': return 'Utah';
            case 'VT': return 'Vermont';
            case 'VA': return 'Virginia';
            case 'WA': return 'Washington';
            case 'DC': return 'Washington DC';
            case 'WV': return 'West Virginia';
            case 'WI': return 'Wisconsin';
            case 'WY': return 'Wyoming';
            default:
                throw new Error(`State or Province ${stateOrProvince} is not currently supported`);
        }
    }

    /**
     * Create hosted payment method fields from an admin location.  This converts ISO 3166 country and state codes into Zuora's
     * representation and populates credit card payment fields.
     * 
     * @param location is from an admin site or account.
     * @param user is a user, such as an account administrator.
     * 
     * @see https://knowledgecenter.zuora.com/CB_Billing/LA_Hosted_Payment_Pages/B_Payment_Pages_2.0/K_Payment_Pages_2.0_Form_Fields
     * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
     * @see https://en.wikipedia.org/wiki/ISO_3166-2:US
     */
    public static getZuoraHpmFieldsFromLocationAndUser(location: Location, user: User): HpmFields {
        let country, stateOrProvince;
        if (!location.address) {
            throw new Error(`Location address is a required property`);
        }
        if (user && (!user.email || !user.displayName)) {
            throw new Error(`User email and display name are required properties`);
        }

        const countryMeta = environment.countries.find(c => c.code === location.address.country);
        if (countryMeta) {
            country = countryMeta.codeAlpha3; // zuora wants iso alpha 3 code
        }

        //country = this.getZuoraCountry(location.address.country as string);
        //conditionally setup state province if needed for country support. Typically this is US / CA / MX and AU
        if (countryMeta.hasAddressRegions) {
            stateOrProvince = this.getZuoraStateOrProvince(location.address.stateOrProvince as string);
        }

        const hpmFields: HpmFields = {
            creditCardAddress1: location.address.street,
            creditCardAddress2: location.address.street2,
            creditCardCity: location.address.city,
            creditCardCountry: country,
            creditCardState: stateOrProvince,
            creditCardPostalCode: location.address.zipCode,
            creditCardHolderName: user.displayName,
            email: user.email
        };

        return hpmFields;
    }

    /**
     * Create hosted payment method fields from an ILocation.  This converts ISO 3166 country and state codes into Zuora's
     * representation and populates credit card payment fields.
     * 
     * @param location is an ILocation.
     * 
     * @see https://knowledgecenter.zuora.com/CB_Billing/LA_Hosted_Payment_Pages/B_Payment_Pages_2.0/K_Payment_Pages_2.0_Form_Fields
     * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
     * @see https://en.wikipedia.org/wiki/ISO_3166-2:US
     */
    public static getZuoraHpmFieldsFromILocation(location: ILocation): HpmFields {

        let country, stateOrProvince;

        const countryMeta = environment.countries.find(c => c.code === location.country);
        if (countryMeta) {
            country = countryMeta.codeAlpha3; // zuora wants iso alpha 3 code
        }

        //conditionally setup state province if needed for country support. Typically this is US / CA / MX and AU
        if (countryMeta.hasAddressRegions) {
            stateOrProvince = this.getZuoraStateOrProvince(location.stateOrProvince as string);
        }

        const hpmFields: HpmFields = {
            creditCardAddress1: location.address,
            creditCardAddress2: location.address2,
            creditCardCity: location.city,
            creditCardCountry: country,
            creditCardState: stateOrProvince,
            creditCardPostalCode: location.zipOrPostalCode
        };

        return hpmFields;
    }
}