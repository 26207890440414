import { Injectable, OnInit, OnDestroy } from '@angular/core';

@Injectable()
export abstract class BaseDataComponent implements OnInit, OnDestroy {
  active = false;

  ngOnInit(): void {
    this.active = true;
  }

  ngOnDestroy(): void {
    this.active = false;
  }
}
