import { Injectable } from '@angular/core';
import {
  Account,
  SitePostRequest,
  SitePutRequest,
  User,
  UserAccessCodePutRequest,
  UserCollection,
  UserPostRequest,
  WorkspacePostRequest
} from '@mitel/cloudlink-sdk/admin';
import { AdminService as CloudAdmin, AuthenticationService as CloudAuth, Config, Odata, Site, Token } from '@mitel/cloudlink-sdk';
import { from, Observable, of } from 'rxjs';
import {
  AccountPutRequest,
  Group,
  GroupPostRequest,
  Tag,
  UserWelcomeEmailRequest,
  // ContactCollection,
  ContactPostRequest
} from '../../../node_modules/@mitel/cloudlink-sdk/admin/admin-service.g';
import { RouteUtils } from '../shared/utils/route-utils';
import { StorageItemName, StorageService } from './storage-service.service';
import { environment } from '../../environments/environment';
import { UserType } from '../shared/models/user-type-enum';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { AccountTagKeys } from '../shared/models/user-tag-keys.enum';

@Injectable()
export class AdminService {
  private readonly api: CloudAdmin;
  private readonly clAuthService: CloudAuth;
  private readonly applicationIdHeader: any = {
    headers: {
      'x-mitel-application-id': environment.clientId
    }
  };

  constructor(
    private storageService: StorageService,
    private http: HttpClient
  ) {
    //const cloud = RouteUtils.isDevHost(location.hostname) ? 'dev' : 'public';
    //Config.cloud = cloud;
    this.clAuthService = Config.authentication;
    this.api = new CloudAdmin();
    console.log('this.api', this.api);
  }

  public signUpUser(body: UserPostRequest): Observable<User> {
    console.log(`${this.constructor.name}->signUpUser: UserPostRequest... sending`);
    return from(this.api.createUser({ body }));
  }

  public createWorkspace(body: WorkspacePostRequest): Observable<Account> {
    console.log(`${this.constructor.name}->createWorkspace: WorkspacePostRequest`);
    const params = {
      body,
      options: Object.assign({}, this.applicationIdHeader)
    };
    return from(this.api.createWorkspace(params));
  }

  public updateAccount(accountId: string, body: AccountPutRequest): Observable<any> {
    console.log(`${this.constructor.name}->updateAccount: AccountPutRequest`, body);
    return from(this.api.updateAccount({ accountId, body }));
  }

  public sendAccessCode(uniqueUserId: string, options?: any): Observable<any> {
    const body: any = {
      type: 'email',
      url: RouteUtils.getVerificationCodeURL()
    };

    const params = {
      userId: uniqueUserId,
      body: body,
      options: Object.assign({}, options, this.applicationIdHeader)
    };
    return from(this.api.sendUserAccessCode(params));
  }

  public verifyAccessCode(uniqueUserId: string, temporaryAccessCode: string): Observable<User> {
    const body: UserAccessCodePutRequest = {
      tempAccessCode: temporaryAccessCode
    };
    const params = {
      userId: uniqueUserId,
      body: body,
    };

    return from(this.api.verifyUserAccessCode(params));
  }

  createSite(params: {
    accountId: string;
    body: SitePostRequest;
    options?: any;
  }): Observable<Site | any> {
    console.log('Creating site for --> ', params);
    return from(this.api.createSite(params));
  }

  sendWelcomeToUserByAccountId(params: {
    accountId: string;
    userId: string;
    body: UserWelcomeEmailRequest;
    options?: any;
  }, userType?: UserType): Observable<any> {

    if (userType) {
      params['body']['type'] = userType;
    }

    console.log('Sending welcome email for --> ', params);
    return from(this.api.sendWelcomeToUserByAccountId(params));
  }

  createUserInAccount(params: {
    accountId: string;
    body: UserPostRequest;
    options?: any;
  }): Observable<User> {
    console.log('Creating a user in the account');
    return from(this.api.createUserInAccount(params));
  }

  updateUser(userId: string, userPostRequest: any, options?: any): Observable<User> {
    return from(this.api.updateUser({
      userId: userId,
      body: userPostRequest,
      options: options
    }));
  }

  deleteSite(params: {
    accountId: string;
    siteId: string;
    options?: any;
  }): Observable<any> {
    return from(this.api.deleteSite(params));
  }

  updateSite(accountId: string, siteId: string, body: SitePutRequest, options?: any): Observable<Site | any> {
    return from(this.api.updateSite({
      accountId: accountId,
      siteId: siteId,
      body: body,
      options: options
    }));
  }

  userExists(params: {
    userId: string;
    options?: any;
  }): Observable<any> {
    return from(this.api.userExists(params));
  }

  createAccountTag(tagKey: AccountTagKeys, tagValue: any): Observable<any> {

    const body = {};
    body[tagKey] = tagValue;
    const params = {
      accountId: (this.storageService.getLocalStorageItem(StorageItemName.USER)).accountId,
      body
    };

    return from(this.api.createAccountTag(params))
      .pipe(
        map((createdAccountTag: Tag) => {
          let existingAccountTags = this.storageService.getLocalStorageItem(StorageItemName.ACCOUNT_TAG) ? this.storageService.getLocalStorageItem(StorageItemName.ACCOUNT_TAG) : {};
          existingAccountTags = Object.assign(existingAccountTags, createdAccountTag);
          this.storageService.setLocalStorageItem(StorageItemName.ACCOUNT_TAG, existingAccountTags);
          return createdAccountTag;
        })
      );
  }

  getAccountTag(params: {
    accountId: string;
    tagId: string;
    options?: any;
  }): Observable<Tag> {
    return from(this.api.getAccountTag(params)).pipe(
      catchError(() => of(null))
    );
  }

  getUser(params: {
    userId: string;
    options?: any;
  }): Observable<User> {
    return from(this.api.getUser(params));
  }

  createGroup(params: {
    body: GroupPostRequest;
    options?: any;
  }): Observable<Group> {
    return from(this.api.createGroup(params));
  }

  getAccount(accountId: string): Observable<Account> {
    return from(this.api.getAccount({ accountId: accountId }));
  }

  createGateway(accountId: string, body: any): Observable<any> {

    const token: Token = this.storageService.getLocalStorageItem(StorageItemName.TOKEN);
    const headers = new HttpHeaders({
      'Authorization': `${token ? token.access_token : ''}`,
      'Content-Type': 'application/json'
    });
    const cloud = RouteUtils.isDevHost(location.hostname) ? 'dev' : 'public';
    let endpoint = '';

    if (cloud === 'dev') {
      endpoint = `https://admin.dev.api.mitel.io/2017-09-01/accounts/${accountId}/services/gateways`;
    } else {
      endpoint = `https://admin.api.mitel.io/2017-09-01/accounts/${accountId}/services/gateways`;
    }

    return this.http.post(endpoint, body, { headers });
  }

  createContacts(accountId: string, body: ContactPostRequest): Observable<any> { //TODO: replace any with ContactCollection
    return from(this.api.createAccountContacts({ accountId, body }));
  }

  getUsers(oData?: Odata, options?: any): Observable<UserCollection | any> {
    return from(oData ? this.api.getUsers({
      $SkipToken: oData.$SkipToken,
      $Top: oData.$Top,
      $Filter: oData.$Filter,
      $Search: oData.$Search,
      $Expand: oData.$Expand,
      options
    }) : this.api.getUsers({ options })
    );
  }

  getSite(params: {
    accountId: string;
    siteId: string;
    $Expand?: string;
    options?: any;
  }): Observable<Site> {
    return from(this.api.getSite(params));
  }
}
