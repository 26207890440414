import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: [ './not-found.component.scss' ]
})
export class NotFoundComponent {

  constructor(
    private router: Router
  ) {
  }

  goToHomePage(event: any): void {
    event.preventDefault();
    this.router.navigate([ '/getStarted' ]);
  }

}
