import { Injectable } from '@angular/core';
import { AuthenticationService as CloudAuth, Config, Token, User } from '@mitel/cloudlink-sdk';
import { environment } from '../../environments/environment';
import { from, Observable, of } from 'rxjs';
import { AuthCode } from '@mitel/cloudlink-sdk/authentication';
import { RouteUtils } from '../shared/utils/route-utils';
import { UserDetails } from './registration.service';
import { StorageItemName, StorageService } from './storage-service.service';
import { PageName } from '../shared/models/page-name.enum';
import { AdminService } from './admin.service';
import { SsoProviderResults } from '../../../node_modules/@mitel/cloudlink-sdk/authentication/authentication-service.g';
import { catchError, concatMap, flatMap, switchMap } from 'rxjs/operators';
import { AccountTagKeys } from '../shared/models/user-tag-keys.enum';
import { ActivatedRoute, Router } from '@angular/router';

export interface ICreateAuthCodeRequest {
  clientId: string;
  responseType: string;
  username: string;
  password: string;
  scope?: string;
  accountId?: string;
  redirectUri?: string;
  options?: any;
}

@Injectable()
export class AuthService {

  private readonly clAuthService: CloudAuth;
  private clientURL: string;
  private scope: string;
  public authCode: string;

  constructor(private storageService: StorageService,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute) {
    //const cloud = RouteUtils.isDevHost(location.hostname) ? 'dev' : 'public';
    //Config.cloud = cloud;
    this.clAuthService = Config.authentication;
    this.scope = '';

  }

  public login(params): Observable<Token> {
    return from(this.clAuthService.login(params));
  }

  createAuthCode(requestParams: ICreateAuthCodeRequest): Observable<AuthCode> {
    return from(this.clAuthService.createAuthorizationCode(requestParams));
  }

  routeToDashboard(): void {
    console.log('routeToDashboard');
    const adminURL = RouteUtils.getAdminURL(location.hostname);

    if (this.storageService.getLocalStorageItem(StorageItemName.USERCODE) !== null) {
      const code = this.storageService.getLocalStorageItem(StorageItemName.USERCODE);
      this.createTagAndChangeLocation(adminURL, code);
    } else {
      const user: UserDetails = this.storageService.getLocalStorageItem(StorageItemName.USER);
      const requestParams: ICreateAuthCodeRequest = {
        clientId: environment.clientId,
        responseType: 'code',
        username: user.uniqueUserId,
        password: user.password
      };

      this.createAuthCode(requestParams)
        .subscribe(({ code }: AuthCode) => {
          this.createTagAndChangeLocation(adminURL, code);
        });
    }
  }

  private createTagAndChangeLocation(adminURL: string, code: string): void {
    this.adminService.createAccountTag(AccountTagKeys.LAST_PAGE, PageName.DASHBOARD)
      .pipe(
        switchMap(() => this.storageService.clearLocalStorage())
      )
      .subscribe(() => window.location.href = `${adminURL}?code=${code}`);
  }



  getSsoProviders(params: {
    clientId?: string;
    options?: any;
  }): Observable<SsoProviderResults> {
    return from(this.clAuthService.getSsoProviders(params));
  }

  loginSsoProvider(params: {
    providerId: string;
    clientId: string;
    redirectUri: string;
    action?: string;
    scopes?: string;
    accountId?: string;
    userId?: string;
    options?: any;
  }): Observable<any> {
    return from(this.clAuthService.loginSsoProvider(params));
  }

  doSsoLogin(params): Observable<User> {
    return this.login(params)
      .pipe(
        flatMap((token: Token) => {
          const ssoToken = token.sso.accessToken;
          this.storageService.setLocalStorageItem(StorageItemName.SSOTOKEN, ssoToken);
          return this.adminService.getUser({ userId: 'me' });
        })
      );
  }


  private loginUsingCode(codeParams: any): Observable<Token | any> {
    return from(this.login(codeParams));
  }

  logoutUser(): Observable<any> {
    return from(this.clAuthService.logout());
  }

  logoutUserAndLoginUsingCode(): Observable<User> {
    return this.logoutUser()
      .pipe(
        switchMap(() => {
          if (this.authCode) {
            const param: any = {
              code: {
                code: this.authCode,
                clientId: environment.clientId,
                redirect_uri: this.clientURL,
                scope: this.scope
              }
            };

            return this.loginUsingCode(param)
              .pipe(
                switchMap((token: Token) => {
                  // this.authCode = null;
                  if (token) {
                    return this.adminService.getUser({ userId: 'me' });
                  }
                  return of(null);
                })
              );
          }
          return of(null);
        }));
  }

  loginUsingAuthCode(): Observable<boolean> {
    return this.logoutUserAndLoginUsingCode()
      .pipe(
        switchMap((user: User) => {
          if (user) {
            if (user.userId === this.storageService.getLocalStorageItem(StorageItemName.USER)['userId']) {
              return of(true);
            } else {
              this.storageService.clearLocalStorage([StorageItemName.MARKETING_INFO, StorageItemName.TERM]);
              return this.logoutUser().pipe(concatMap(() => {
                this.router.navigate(['/getStarted/signup'], { relativeTo: this.route.parent, queryParams: { login: true } });
                return of(false);
              }));
            }
          } else {
            return of(false);
          }
        }),
        catchError((err) => {
          console.log(err, 'in logging in with Auth Code');
          this.router.navigate(['/getStarted/signup'], { relativeTo: this.route.parent, queryParams: { login: true } });
          return of(false);
        })
      );
  }
}
