import { VERSION } from './version';
import { SUPPORTED_COUNTRIES } from './countries';

export const environment = {
  production: false,
  cloud: 'dev',
  googlePlacesKey: 'AIzaSyBx4_mNQgu0MItuCxlUVXcxc8F7j8jZb_U',
  googleGeocodeKey: 'AIzaSyAUF_ZIcxeCcUDxJRDOxlMNQ02pzQfGJyU',
  clientId: 'd0506b6f3d6044eaa09d41157ced1b05',
  bugSnagKey: 'c1b990eadf20bf2659c89f29a189217e',
  purchasePromoCode: 'mitel-galaxy',
  analyticsConfig: {
    enableLogging: true,
    identityPoolId: 'us-east-1:6dfbfa57-62d9-4484-afbe-bcd11997ec81',
    cognitoIdentity: 'us-east-1:7cdb76a8-4e6d-4131-9dc9-bb602fa4692f',
    appId: '45ad2ed84cbc424aae50dc2870aac99f'
  },
  appVersion: VERSION.appVersion,
  buildDate: VERSION.buildDate,
  authUrl: 'https://auth.dev.talkify.com/authorize',
  countries: SUPPORTED_COUNTRIES,
  allowBetaCountries: false,
  defaultCountry: 'US',
  defaultLang: 'en',
  defaultTimezone: 'America/Toronto'
};
