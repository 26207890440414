import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material';
import { SpinnerComponent } from '../modules/get-started/shared/spinner/spinner.component';

export interface DialogData {
  spinnerMessage: string;
}

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private _isSpinnerShown = false;

  constructor(public dialog: MatDialog) {}

  showSpinner(spinnerMessage?: string): void {
    this._isSpinnerShown = true;
    setTimeout(() => {
      const dialogRef = this.dialog.open(SpinnerComponent, {
        width: 'auto',
        data: {spinnerMessage: spinnerMessage}
      });

      dialogRef.afterClosed().subscribe(result => {
        this._isSpinnerShown = false;
        console.log('The dialog was closed');
      });
    });
  }

  isShown(): boolean {
    return this._isSpinnerShown;
  }

  hideSpinner(): void {
    this._isSpinnerShown = false;
    setTimeout(() => this.dialog.closeAll());
  }
}
