import { Component , EventEmitter , Input , OnInit , Output } from '@angular/core';
import { ILocation } from '../../models/location.interface';
import { SsoSource } from '../../models/sso-source.enum';

@Component({
  selector: 'selected-location',
  templateUrl: './selected-location.component.html',
  styleUrls: ['./selected-location.component.scss']
})
export class SelectedLocationComponent implements OnInit {

  private _locationInfo: ILocation;
  ssoSourceType = SsoSource;

  @Input() set locationInfo(locationInfo: ILocation) {
    this._locationInfo = locationInfo;
  }
  @Input() ssoSource?: string;
  @Output() modifyClicked: EventEmitter<string> = new EventEmitter<string>();

  get locationInfo(): ILocation {
    return this._locationInfo;
  }

  constructor() { }

  ngOnInit() {
  }

  modifyAddress() {
    this.modifyClicked.emit();
  }

}
