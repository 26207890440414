import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ILocation } from '../shared/models/location.interface';

@Injectable({
  providedIn: 'root'
})
export class GoogleGeocodingService {

  constructor(private http: HttpClient) { }

  getLatLongFromLocation(cityName: string, provinceOrState: string, country: string): Observable<any> {
    console.log('getLatLongFromLocation', cityName, provinceOrState, country);
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${cityName},+${provinceOrState},+${country}&key=${environment.googleGeocodeKey}`);
  }
}
