import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import {BehaviorSubject, combineLatest, merge, Observable, Subscription} from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BaseDataComponent } from '../../modules/get-started/shared/base-data-component';

@Directive({
  selector: '[invalidMessage]'
})
export class InvalidMessageDirective extends BaseDataComponent implements OnInit, OnDestroy {

  @Input() invalidMessage: string;
  control: AbstractControl;
  controlValue$: Observable<any>;
  controlSubscription: Subscription;
  validate: boolean;

  constructor(
    private _fg: ControlContainer,
    private _el: ElementRef,
    private render: Renderer2
  ) {
    super();
  }

  get form() {
    return this._fg.formDirective ? ( this._fg.formDirective as FormGroupDirective ).form : null;
  }

  ngOnInit() {
    super.ngOnInit();
    const formSubmit$ = ( <FormGroupDirective>this._fg ).ngSubmit;
    const formSubmitSubject = new BehaviorSubject(null);
    this.control = this.form.get(this.invalidMessage);
    const valueSubject = new BehaviorSubject(this.control.value);
    formSubmit$.pipe(takeWhile(() => this.active)).subscribe((event) => formSubmitSubject.next(event));
    this.control.statusChanges.pipe(takeWhile(() => this.active)).subscribe((value) => valueSubject.next(value));
    this.controlValue$ = combineLatest([valueSubject, formSubmitSubject]);
    this.controlSubscription = this.controlValue$
      .pipe(takeWhile(() => this.active))
      .subscribe(([value, submits]) => {
        this.validate = ( <FormGroupDirective>this._fg ).submitted || !this.control.pristine;
        this.setVisibility();
      });
  }

  private setVisibility() {
    if ( this.control.invalid && this.validate ) {
      this.render.addClass(this._el.nativeElement, 'error');
      this.render.setStyle(this._el.nativeElement, 'display', 'block');
    } else {

      this.render.setStyle(this._el.nativeElement, 'display', 'none');
    }
  }

  matchErrors( error: string ) {
    console.log('Function: matchErrors, error: ', error);
    if ( this.control && this.control.errors ) {
      if ( Object.keys(this.control.errors).indexOf(error) > -1 ) {
        return true;
      }
    }
    return false;
  }
}
