import { Component, OnInit } from '@angular/core';
import { BaseDataComponent } from '../get-started/shared/base-data-component';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, takeWhile } from 'rxjs/operators';
import { AdminService } from '../../services/admin.service';
import { switchMap } from 'rxjs/operators';
import { FORBIDDEN } from 'http-status-codes';
import { StorageItemName, StorageService } from '../../services/storage-service.service';
import { RegistrationService, UserDetails } from '../../services/registration.service';
import { AuthService } from '../../services/auth.service';
import { Token, User } from '@mitel/cloudlink-sdk';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent extends BaseDataComponent implements OnInit {
  user: UserDetails;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private storageService: StorageService,
    private registrationService: RegistrationService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.storageService.getLocalStorageItem(StorageItemName.USER);
    this.route.queryParams
      .pipe(takeWhile(() => this.active))
      .subscribe((params: any) => {
        if (params && params['code']) {
          this.verifyCode(params['code']);
        } else {
          this.goToErrorPage();
        }
    });
  }

  verifyCode(code: string): void {
    console.log('verifyCode', code);
    this.adminService.verifyAccessCode(this.user.uniqueUserId, code)
      .pipe(
        takeWhile(() => this.active),
        switchMap((user: User) => {
          // Set the verified from the response as it should be true
          this.user.emailVerified = user.emailVerified;
          const params = {
            user: {
              username: this.user.uniqueUserId,
              password: this.user.password,
              clientId: environment.clientId
            }
          };
          return this.authService.login(params);
        }))
      .subscribe( () => {
        this.registrationService.user = this.user;
        this.storageService.setLocalStorageItem(StorageItemName.USER , this.user);
        this.router.navigate(['/getStarted/location']);
      }, (error: any) => {
        switch (error.statusCode) {
          case FORBIDDEN:
            console.log('invalid code');
            this.goToErrorPage();
            break;
        }
      });
  }

  goToErrorPage(): void {
    this.router.navigate(['/error']);
  }
}
