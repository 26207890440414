import { Component } from '@angular/core';

@Component({
  selector: 'registration-container',
  templateUrl: './registration-container.component.html',
  styleUrls: [ './registration-container.component.scss' ]
})
export class RegistrationContainerComponent {
  constructor() {
  }
}
