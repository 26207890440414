import { Directive , EventEmitter , HostListener , Output } from '@angular/core';

@Directive({
  selector: '[capsLock]'
})
export class CapsLockDirective {

  constructor() { }
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.onKeyUpOrDown(event);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.onKeyUpOrDown(event);
  }

  onKeyUpOrDown(event: KeyboardEvent): void {
    const capsOn = event.getModifierState && event.getModifierState('CapsLock');
    this.capsLock.emit(capsOn);
  }
}
