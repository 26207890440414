import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserLocationService {
  // https://ipapi.co/api
  private url = 'https://ipapi.co/json';

  constructor(private http: HttpClient) { }

  // Location: max 1000 free requests per day
  getIpLocation(): Observable<any> {
    return this.http.get(this.url)
      .pipe(
        retry(2),
        catchError((err) => {
          return of(null);
        })
      );
  }
}
