import { Injectable } from '@angular/core';
import { AdminService } from './admin.service';
import { Observable, of } from 'rxjs';
import { TextUtils } from '../shared/utils/text-utils';
import { Account, User, UserPostRequest, WorkspacePostRequest } from '@mitel/cloudlink-sdk/admin';
import { switchMap } from 'rxjs/operators';

export class RegistrationCompany {
  name: string;
  country: string;
  address: string;
  address2?: string;
  city: string;
  stateOrProvince: string;
  zipOrPostalCode: string;
}

export class UserDetails implements User {
  email: string;
  name: string;
  password: string;
  uniqueUserId: string;
  accountId?: string;
  emailVerified?: boolean;
  company?: RegistrationCompany;
  officePhone?: string;
}

@Injectable()
export class RegistrationService {

  private _user: UserDetails = null;

  set user(userData: UserDetails) {
    this._user = userData;
  }

  get user(): UserDetails {
    return this._user;
  }

  constructor(
    private adminService: AdminService
  ) {

  }

  registerUser(user: UserDetails): Observable<any> {
    this.user = user;
    console.log(`${this.constructor.name}->registerUser`);

    //TODO Incororate company name in the future and edit workspaceName to company name

    const { name, email, password } = this.user;
    const workspaceRequest: WorkspacePostRequest = {
      workspaceName: name,
      userName: name,
      email: email,
      password: password
    };

    return this.adminService.createWorkspace(workspaceRequest).pipe(
      switchMap((account: Account) => {
        console.log(`Workspace created: Response`, account);

        const userRequest: UserPostRequest = {
          accountId: account.accountId,
          name: name,
          email: email,
          password: password
        };

        // At some point there will need to be a call to create the user's office phone number.
        // More than likely it will end up in here
        return this.adminService.signUpUser(userRequest);
      }),
      switchMap((user: User) => {
        console.log('Registration User created:', this.user.name);
        Object.assign(this.user, user);
        return this.adminService.sendAccessCode(this.user.uniqueUserId);
      })
    );
  }

  getOfficePhone(): Observable<string[]> {

    const numbers: string[] = [];

    for (let count = 0; count < 10; count++) {
      const randomPhone: number = Math.floor(Math.random() * 9000000000) + 1000000000;
      numbers.push(TextUtils.normalizePhoneNumber(randomPhone));
    }

    return of(numbers);
  }
}
