import { Injectable } from '@angular/core';
import { Observable , of } from 'rxjs';

export enum StorageItemName {
  USER = 'userDetails',
  SITE = 'site',
  ACCOUNT_TAG = 'accountTag',
  USERCODE = 'userCode',
  SSOTOKEN = 'ssoToken',
  BUSINESS_NUMBER = 'businessNumber',
  MARKETING_INFO = 'marketingInfo',
  TOKEN = '@mitel/token',
  USERS = 'numberOfUsers',
  TERM = 'paymentTerm'
}

@Injectable({
  providedIn: 'root'
})


export class StorageService {

  constructor() { }

  setLocalStorageItem(itemName: StorageItemName, item: any): void {
    localStorage.setItem(String(itemName), JSON.stringify(item));
  }

  getLocalStorageItem(itemName: StorageItemName): any {
    return JSON.parse(localStorage.getItem(String(itemName)));
  }

  setSessionStorageItem(itemName: StorageItemName, item: any): void {
    sessionStorage.setItem(String(itemName), JSON.stringify(item));
  }

  getSessionStorageItem(itemName: StorageItemName): any {
    return JSON.parse(sessionStorage.getItem(String(itemName)));
  }

  removeLocalStorageItem(itemName: string): void {
    localStorage.removeItem(itemName);
  }

  clearLocalStorage(omitItems: StorageItemName[] = []): Observable<any> {

    Object.values(StorageItemName)
      .filter( (value) => omitItems.indexOf(value) === -1 )
      .forEach((value: any) => {
        this.removeLocalStorageItem(value);
      });

    return of(null);
  }
}
