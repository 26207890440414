import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StorageItemName , StorageService } from './storage-service.service';
import { Observable , of } from 'rxjs';
import { catchError , concatMap } from 'rxjs/operators';
import { Site } from '@mitel/cloudlink-sdk/admin';
import { AdminService } from './admin.service';

@Injectable()
export class SiteResolver implements Resolve<any> {
  constructor(
    private storageService: StorageService,
    private adminService: AdminService
  ) {}

  resolve(): Observable<Site> {

    if (this.storageService.getLocalStorageItem(StorageItemName.SITE)) {
      return of(this.storageService.getLocalStorageItem(StorageItemName.SITE));
    } else {
        const {accountId , siteId} = this.storageService.getLocalStorageItem(StorageItemName.USER);
        return this.adminService.getSite({accountId , siteId})
          .pipe(
            concatMap((site: Site) => {
              this.storageService.setLocalStorageItem(StorageItemName.SITE , site);
              return of(site);
            }),
            catchError( (err) => {
              console.log(err, 'from getSite in SiteResolver');
              return of(null);
            })
          );
      }
  }
}
