import { HttpParams } from "@angular/common/http";

export class RouteUtils {
  public static readonly ADMIN_STAGE_DEV_URL = 'https://galaxy-stage.dev.mitel.io';
  public static readonly ADMIN_DEV_URL = 'https://galaxy.dev.mitel.io';
  public static readonly ADMIN_PROD_STAGE_URL = 'https://admin-stage.dev.talkify.com';
  public static readonly ADMIN_PROD_DEV_URL = 'https://admin.dev.talkify.com';
  public static readonly ADMIN_PROD_URL = 'https://admin.talkify.com';
  public static readonly ADMIN_LOCAL_DEV_URL = 'http://localhost:4201';


  public static getAdminURL(locationHostName: string): string {
    const hostName = locationHostName.toLowerCase();
    if (hostName.includes('stage.dev.mitel')) {
      return this.ADMIN_STAGE_DEV_URL;
    } else if (hostName.includes('.dev.mitel')) {
      return this.ADMIN_DEV_URL;
    } else if (hostName.includes('stage.dev.talkify')) {
      return this.ADMIN_PROD_STAGE_URL;
    } else if (hostName.includes('.dev.talkify')) {
      return this.ADMIN_PROD_DEV_URL;
    } else if (hostName.includes('join.talkify')) {
      return this.ADMIN_PROD_URL;
    } else {
      return this.ADMIN_LOCAL_DEV_URL;
    }
  }

  public static isDevHost(locationHostName): boolean {
    return (locationHostName.includes('.dev.') ||
      locationHostName === 'localhost' ||
      locationHostName === '127.0.0.1');
  }

  public static getVerificationCodeURL(): string {
    return `${window.location.origin}/verify-code`;
  }

  public static getQueryParam(paramName) {
    // why is this needed? well you can't use activated route outside of a <router-outlet> thats why
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
}
