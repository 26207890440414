import { SpinnerService } from '../../services/spinner.service';
import { Observable } from 'rxjs';


export const spinnerPipe = (spinnerService: SpinnerService) => (source: Observable<any>) => {

  spinnerService.showSpinner();

  return new Observable(observer => {
    return source.subscribe({
      next: (originalSource: Observable<any>) => observer.next(originalSource),
      error: ( err ) => {
        observer.error(err);
        spinnerService.hideSpinner();
      },
      complete: () => {
        spinnerService.hideSpinner();
        observer.complete();
      }
    });
  });
};






