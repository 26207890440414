import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { BaseDataComponent } from './modules/get-started/shared/base-data-component';
import { BrowserLocationService } from './services/browser-location.service';
import { AnalyticsService } from './services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { RouteUtils } from './shared/utils/route-utils';
import { WebpackTranslateLoader } from './webpack-translate-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent extends BaseDataComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private browserLocation: BrowserLocationService,
    private analyticsSrv: AnalyticsService,
    private route: ActivatedRoute
  ) {
    super();
    this.setLanguage();
  }

  ngOnInit(): void {
    super.ngOnInit();
    // 1. Wait for location, only than config Amplify
    this.browserLocation.getIpLocation()
      .pipe(takeWhile(() => this.active))
      .subscribe((data) => {
        let location;
        if (data) {
          location = {
            timezone: data['timezone'],
            city: data['city'],
            region: data['region_code'],
            country: data['country']
          };
        } else {
          location = null;
        }

        console.log('app.component.ngOnInit()..getIpLocation()', location);
        const currentCountry = location.country;
        const country = environment.countries.find(c => c.code === currentCountry);
        if (country) {
          console.log('app.component.ngOnInit()..setting default country', currentCountry);
          environment.defaultCountry = currentCountry;
          if (country.status === 'BETA') {
            console.log('app.component.ngOnInit()..allowing beta countries');
            environment.allowBetaCountries = true;
          }
        }

        if (location && location.timezone) {
          environment.defaultTimezone = location.timezone;
        }

        this.analyticsSrv.amplifyConfig(location);
      });

    // TODO: does this code even work given that app.component isn't in ,router-outlet? subscribe on query params doesn't
    // work outside of this?
    this.route.queryParams.subscribe((param) => {
      console.log('Function: , param: ', param);
      if (param && (param.success === 'true' || param.success && param.success.length && param.success !== 'false')) {
        console.log('redirect from hosted payment method');
        const refId = this.route.snapshot.queryParamMap.get('refId');
        sessionStorage.removeItem('payment_method_success');
        sessionStorage.setItem('payment_method_success', refId);
      } else if (param && param.success === 'false') {
        const errorCode = param.errorCode;
        const errorMessage = param.errorMessage;
        sessionStorage.removeItem('payment_method_failed');
        sessionStorage.setItem('payment_method_failed', JSON.stringify({ errorCode, errorMessage }));
      }
    });

    this.checkQueryParamOptions();
  }

  private setLanguage() {

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en');
    const langs = WebpackTranslateLoader.getSupportedLangs();
    // this is an optional query string to set the lang
    const queryLang: string = RouteUtils.getQueryParam('lang');
    // this is your default browser lang including sublang i.e. en-US
    const browserLang = navigator.language;
    const browserLangRoot = navigator.language.substr(0, 2);
    // try to find the full regional lang first


    if (langs.indexOf(queryLang) >= 0) {
      environment.defaultLang = queryLang;
      this.translateService.use(queryLang);
    }
    else if (langs.indexOf(browserLang) >= 0) {
      // we have the exact region lang culture. Load it
      environment.defaultLang = browserLang;
      this.translateService.use(browserLang);
    }
    else if (langs.indexOf(browserLangRoot) >= 0) {
      // we have the base lang
      environment.defaultLang = browserLangRoot;
      this.translateService.use(browserLangRoot);
    }
    else {
      this.translateService.use('en');
    }

    console.log(`app.component.setLanguage().. default:${this.translateService.defaultLang} current:${this.translateService.currentLang}`);

  }

  private checkQueryParamOptions() {
    // not the cleanest but route.queryParms are not available in app.component as it's not part of the activated routes
    const beta = RouteUtils.getQueryParam('beta');
    if (beta) {
      console.log('app.component.checkQueryParamOptions()...beta countries enabled');
      environment.allowBetaCountries = true;
    }
  }
}
