import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { VerifyCodeComponent } from './modules/verify-code/verify-code.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'getStarted/signup',
    pathMatch: 'full'
  },
  {
    path: 'verify-code',
    component: VerifyCodeComponent
  },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: NotFoundComponent }
];

export const AppRouting = RouterModule.forRoot(appRoutes);
