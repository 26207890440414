import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor( private toast: MatSnackBar ) {
  }

  showToast(message: string, duration?: number, cssClass?: string, action?: string) {
    this.toast.open(
      message,
      action ? action : '',
      { panelClass: [cssClass],
        duration: duration ? duration : 3000,
        verticalPosition: 'top'
      }
    );
  }

  hideToast() {
    this.toast.dismiss();
  }

  openFromComponent(displayComponent: ComponentType<any>, duration: number): void {
    this.toast.openFromComponent(displayComponent, {duration, verticalPosition: 'top'});
  }
}
