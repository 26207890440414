import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleSsoService {

  constructor(private http: HttpClient) { }

  getGoogleAccounts(headers: HttpHeaders): Observable<any> {
    return this.http.get('https://mybusiness.googleapis.com/v4/accounts', {headers});
  }

  getGoogleAccountLocations(accountRoute: string, headers: HttpHeaders): Observable<any> {
    return this.http.get(`https://mybusiness.googleapis.com/v4/${accountRoute}/locations`, {headers});
  }
}
