import { FormGroup } from '@angular/forms';

export class FormUtils {

  public static markFormFieldsAsDirty(form: FormGroup): void {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
