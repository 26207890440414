import { Injectable } from '@angular/core';
import Amplify, { Analytics, Logger } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { TextUtils } from '../shared/utils/text-utils';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor() { }

  amplifyConfig(location: any) {
    Amplify.configure({
      // To get the AWS Credentials, you need to configure
      // the Auth module with your Cognito Federated Identity Pool
      Auth: {
        identityPoolId: environment.analyticsConfig.identityPoolId,
        cognitoIdentity: environment.analyticsConfig.cognitoIdentity,
        region: 'us-east-1'
      },
      Analytics: {
        disabled: false, // OPTIONAL - disable Analytics if true
        autoSessionRecord: true, // OPTIONAL - Allow recording session events. Default is true.

        AWSPinpoint: {
          appId: environment.analyticsConfig.appId,
          region: 'us-east-1',
          // OPTIONAL - Default Endpoint Information
          endpoint: {
            address: '', // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
            demographic: {
              appVersion: environment.appVersion, // The version of the application associated with the endpoint.
              // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
              locale: window.navigator.language,
              timezone: this.timezone
            },
            metrics: {
              // Custom metrics that your app reports to Amazon Pinpoint.
            },
            /** Indicates whether a user has opted out of receiving messages with one of the following values:
             * ALL - User has opted out of all messages.
             * NONE - Users has not opted out and receives all messages.
             */
            optOut: 'ALL'
          },
          // Buffer settings used for reporting analytics events.
          bufferSize: 1000, // OPTIONAL - The buffer size for events in number of items.
          flushInterval: 5000, // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
          flushSize: 100, // OPTIONAL - The number of events to be deleted from the buffer when flushed.
          resendLimit: 5 // OPTIONAL - The limit for failed recording retries.
        }
      }
    });

    // If location retrieved set endpoint
    if (location) {

      if (environment.analyticsConfig.enableLogging) { console.log('IP based location:', location); }

      Analytics.updateEndpoint({
        demographic: {
          timezone: location['timezone']
        },
        location: {
          city: location['city'],
          region: location['region'],
          country: location['country']
        }
      });
    }

    Analytics.autoTrack('pageView', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, the event name, by default is 'pageView'
      eventName: 'Page view',
      // OPTIONAL, the attributes of the event, you can either pass an object or a function
      // which allows you to define dynamic attributes
      //    attributes: {
      //        attr: 'attr'
      //    },
      // when using function
      // attributes: () => {
      //    const attr = somewhere();
      //    return {
      //        myAttr: attr
      //    }
      // },
      // OPTIONAL, by default is 'multiPageApp'
      // you need to change it to 'SPA' if your app is a single-page app like React
      type: 'SPA',
      // OPTIONAL, the service provider, by default is the AWS Pinpoint
      provider: 'AWSPinpoint',
      // OPTIONAL, to get the current page url
      getUrl: () => {
        return TextUtils.getPathFromUrl(window.location.pathname);
      }
    });

    Analytics.autoTrack('event', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, events you want to track, by default is 'click'
      events: ['click'],
      // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
      // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
      // always put 'data' as the first prefix
      selectorPrefix: 'data-amplify-analytics-',
      // OPTIONAL, the service provider, by default is the AWS Pinpoint
      provider: 'AWSPinpoint',
      // OPTIONAL, the default attributes of the event, you can either pass an object or a function
      // which allows you to define dynamic attributes
//      attributes: {
//        attr: 'attr'
//      }
      // when using function
      // attributes: () => {
      //    const attr = somewhere();
      //    return {
      //        myAttr: attr
      //    }
      // }
    });

    // Amplify logger
    if (environment.analyticsConfig.enableLogging) {
      Amplify.Logger.LOG_LEVEL = 'DEBUG';
    }

  }

  recordEvent(event: string, data: any): void {
    Analytics.record({ name: event, attributes: data });
  }

  recordCustomClick(data: string): void {
    Analytics.record({
      name: 'click',
      attributes: {
        name: data
      }
    });
  }
}
