import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StorageItemName, StorageService } from './storage-service.service';
import { AdminService } from './admin.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { ILocation } from '../shared/models/location.interface';

@Injectable()
export class LocationResolver implements Resolve<any> {
  constructor(
    private storageService: StorageService,
    private adminService: AdminService
  ) {

  }

  resolve() {
    const userDetail = this.storageService.getLocalStorageItem(StorageItemName.USER);
    if (userDetail && userDetail.accountId) {
      return this.adminService.getAccount(userDetail.accountId)
        .pipe(
          map((account: Account) => {
            if (account && account.location) {
              return {
                companyName: account.name,
                country: String(account.location.address.country),
                address: account.location.address.street,
                address2: account.location.address.street2 || '',
                city: account.location.address.city,
                stateOrProvince: account.location.address.stateOrProvince,
                zipOrPostalCode: account.location.address.zipCode,
                timezone: account.location.timezone
              } as ILocation;
            }
            return null;
          }),
          catchError(() => of(null))
        );
    }
    return of(null);
  }
}
