import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStartedComponent } from './get-started.component';
import { GetStartedRouting } from './get-started.routes';
import { SignupComponent } from './signup/signup.component';
import { LocationComponent } from './location/location.component';
import { NumberComponent } from './number/number.component';
import { InviteComponent } from './invite/invite.component';
import { CardComponent } from './card/card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { InstructionsComponent } from './shared/instructions/instructions.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { RegistrationService } from '../../services/registration.service';
import { RegistrationContainerComponent } from './registration-container/registration-container.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { MatSelectModule } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { VerificationComponent } from './verification/verification.component';
import { LocationResolver } from '../../services/location.resolver';
import { InvitedUsersResolver } from '../../services/invited-users.resolver';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { SiteResolver } from '../../services/site.resolver';
import { CapsLockDirective } from '../../shared/directives/caps-lock.directive';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LangSwitchComponent } from './lang-switch/lang-switch.component';

@NgModule({
  imports: [
    CommonModule,
    GetStartedRouting,
    ReactiveFormsModule,
    SharedModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    NgbPopoverModule
  ],
  declarations: [
    GetStartedComponent,
    RegistrationContainerComponent,
    SignupComponent,
    LocationComponent,
    NumberComponent,
    InviteComponent,
    CardComponent,
    InstructionsComponent,
    VerificationComponent,
    SpinnerComponent,
    CapsLockDirective,
    LangSwitchComponent
  ],
  providers: [
    AdminService,
    RegistrationService,
    AuthService,
    LocationResolver,
    InvitedUsersResolver,
    SiteResolver
  ],
  exports: [
    SpinnerComponent
  ]

})
export class GetStartedModule {
}
