import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../../../services/location.service';
import { MapsAPILoader } from '@agm/core';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { postalAndZipCodeValidator } from '../../custom-validators';
import { FormUtils } from '../../utils/form-utils';
import { TextUtils } from '../../utils/text-utils';
import { ILocation } from '../../models/location.interface';
import { merge } from 'rxjs';
import PlaceResult = google.maps.places.PlaceResult;
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/toast.service';

const tzlookup = require('tz-lookup');

declare const google: any;

@Component({
  selector: 'location-profile-form',
  templateUrl: './location-profile-form.component.html',
  styleUrls: ['./location-profile-form.component.scss']
})
export class LocationProfileFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private autocomplete: any;
  private _locationData: ILocation;
  active = false;
  locationForm: FormGroup;
  countryList: any[];
  provinceOrStateList: any[];
  timezoneList: any[];
  currentCountry = 'US';

  @Input() canShowTimeZoneDropdown = false;
  @Input() showLocationName = true;
  @Input() set locationData(locationData: ILocation) {
    this._locationData = locationData;
    setTimeout(() => {
      if (this.locationForm && this.locationData) {
        this.locationForm.patchValue(this.locationData);
      }
    });
  }

  get locationData(): ILocation {
    return this._locationData;
  }

  @ViewChild('search') searchElementRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private translateService: TranslateService,
    private toastSvc: ToastService
  ) { }

  ngOnInit() {
    this.active = true;
    this.setDefaultCountry();
    this.countryList = this.locationService.getCountryList();
    this.provinceOrStateList = this.locationService.getProvinceOrStateList(this.currentCountry);
    this.timezoneList = this.locationService.getTimezoneList(this.currentCountry);
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.createSubscriptions();
    this.initializePlacesAutoSuggest();

    setTimeout(() => {
      this.checkBetaCountry(this.currentCountry);
    }, 1000);
  }

  ngOnDestroy(): void {
    this.active = false;
  }

  disableFormAutofill() {
    // chrome auto-fill interfers with gmaps. this is the only known hack to stop it
    this.searchElementRef.nativeElement.setAttribute('autocomplete', 'new-password');
  }

  setDefaultCountry(): void {
    this.currentCountry = this.locationData ? this.locationData.country : environment.defaultCountry;
  }

  changeToTitleCase(element: any): void {
    if (element.target.value) {
      const formatted = TextUtils.convertToTitleCase(element.target.value);
      if (formatted) {
        element.target.value = formatted;
        this.locationForm.get(element.target.name).patchValue(formatted);
      }
    }
  }

  changeToUpperCase(element: any): void {
    if (element.target.value) {
      const formatted = String(element.target.value).toUpperCase();
      if (formatted) {
        element.target.value = formatted;
        this.locationForm.get(element.target.name).patchValue(formatted);
      }
    }
  }

  createSubscriptions(): void {
    this.locationForm.get('country').valueChanges
      .pipe(takeWhile(() => this.active))
      .subscribe((value) => {
        // some re-entrant trigger is causing this to be selected twice
        if (this.currentCountry !== value) {

          this.currentCountry = value;
          this.checkBetaCountry(value);
          this.setCountryRestrictions([value]);

          this.provinceOrStateList = this.locationService.getProvinceOrStateList(value);
          this.locationForm.patchValue({ stateOrProvince: '' });

          const useProvState = this.countryUsesRegions(value);
          const stateOrProvince = this.locationForm.get('stateOrProvince');
          if (useProvState) {
            stateOrProvince.setValidators([Validators.required]);
          }
          else {
            stateOrProvince.clearValidators();
          }

          const zipOrPostalCode = this.locationForm.get('zipOrPostalCode');
          //const validators = (value === 'US') ? [Validators.required, zipCodeValidator] : [Validators.required, postalCodeValidator];
          //zipOrPostalCode.setValidators([Validators.required, postalAndZipCodeValidator]);
          zipOrPostalCode.updateValueAndValidity();
          if (this.canShowTimeZoneDropdown) {
            this.timezoneList = this.locationService.getTimezoneList(value);
            this.locationForm.patchValue({ timezone: '' });
          }

        }
      });

    merge(
      this.locationForm.get('city').valueChanges,
      this.locationForm.get('stateOrProvince').valueChanges
    )
      .pipe(
        takeWhile(() => this.active && this.canShowTimeZoneDropdown),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.populateTimezone();
      });
  }

  populateTimezone(): void {
    if (this.canShowTimeZoneDropdown) {
      const formValues = this.getValues();
      if (formValues['country'] && formValues['city']) {
        this.locationService.getTimezoneFromLocation({
          city: formValues['city'],
          stateOrProvince: formValues['stateOrProvince'],
          country: formValues['country']
        })
          .subscribe((timezoneResponse: string) => {
            console.log('populateTimezone->timezoneResponse', timezoneResponse);
            this.locationForm.get('timezone').patchValue(timezoneResponse);
          });
      }
    }
  }

  convertPlaceToLocation(place: PlaceResult): ILocation {
    console.log('place', place);
    const locationObj = {};
    for (const item of place.address_components) {
      locationObj['formattedAddress'] = place.formatted_address;
      if (item['types'].includes('locality') || item['types'].includes('sublocality') || item['types'].includes('postal_town')) {
        locationObj['locality'] = item['long_name'];
      } else if (item['types'].includes('administrative_area_level_1')) {
        locationObj['adminArea1'] = item['short_name'];
      } else if (item['types'].includes('street_number')) {
        locationObj['streetNumber'] = item['short_name'];
      } else if (item['types'].includes('route')) {
        locationObj['route'] = item['long_name'];
      } else if (item['types'].includes('country')) {
        locationObj['country'] = item['short_name'];
      } else if (item['types'].includes('postal_code')) {
        locationObj['postalCode'] = item['short_name'];
      }
    }
    return {
      country: locationObj['country'],
      address: `${locationObj['streetNumber'] ? locationObj['streetNumber'] + ' ' : ''}${locationObj['route']}`,
      city: locationObj['locality'],
      stateOrProvince: locationObj['adminArea1'],
      zipOrPostalCode: locationObj['postalCode'],
      timezone: tzlookup(place.geometry.location.lat(), place.geometry.location.lng())
    } as ILocation;
  }

  getValues(): any {
    return this.locationForm.value;
  }

  getFormattedLocationData(): ILocation {
    const formValues = this.getValues();
    return {
      companyName: formValues.companyName ? formValues.companyName.trim() : '',
      country: formValues.country,
      address: formValues.address ? formValues.address.trim() : '',
      address2: formValues.address2 ? formValues.address2.trim() : '',
      city: formValues.city ? formValues.city.trim() : '',
      stateOrProvince: formValues.stateOrProvince,
      zipOrPostalCode: formValues.zipOrPostalCode ? formValues.zipOrPostalCode.trim() : '',
      timezone: formValues.timezone ? formValues.timezone : ''
    } as ILocation;
  }

  initializeForm(): void {
    this.locationForm = this.fb.group({
      companyName: [this.locationData ? this.locationData.companyName : '', [Validators.required]],
      country: [this.currentCountry, [Validators.required]],
      address: [this.locationData ? this.locationData.address : '', [Validators.required]],
      address2: [this.locationData ? this.locationData.address2 : ''],
      city: [this.locationData ? this.locationData.city : '', [Validators.required]],
      stateOrProvince: [this.locationData ? this.locationData.stateOrProvince : '', [Validators.required]],
      zipOrPostalCode: [this.locationData ? this.locationData.zipOrPostalCode : '', [Validators.required]]
    }, { validator: postalAndZipCodeValidator });

    if (this.canShowTimeZoneDropdown) {
      this.locationForm.addControl(
        'timezone', this.fb.control('', [Validators.required])
      );
    }
  }

  initializePlacesAutoSuggest(): void {
    // maps stuff. keep for now!
    this.mapsAPILoader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });

      let countries: string[] = [];
      if (environment.allowBetaCountries) {
        countries = environment.countries.map(c => c.code.toLowerCase());
      }
      else {
        countries = environment.countries.filter(c => c.status === 'PROD').map(c => c.code.toLowerCase())
      }

      console.log('location-profile-form.component.initializePlacesAutoSuggest()', countries);
      //this.autocomplete.setComponentRestrictions({ country: countries });
      this.setCountryRestrictions(countries);

      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: PlaceResult = this.autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const location = this.convertPlaceToLocation(place);
          this.locationData = Object.assign({}, this.getValues(), location);
          console.log('location', this.locationData);
        });
      });
    });
  }

  isValid(): boolean {
    return this.locationForm.valid;
  }

  requireStateOrProvince(): boolean {
    const country = this.locationForm.get('country').value;
    return this.countryUsesRegions(country);
  }

  validate(): void {
    FormUtils.markFormFieldsAsDirty(this.locationForm);
  }

  countryChanged(selectedCountry: string): void {
    console.log('countryChanged', selectedCountry);
    const foundCountry = this.countryList.find((country: any) => {
      return country.name.toLowerCase() === selectedCountry.toLowerCase() || country.code.toLowerCase() === selectedCountry.toLowerCase();
    });
    if (foundCountry) {
      this.locationForm.get('country').setValue(foundCountry.code);
    }
  }

  regionChanged(selectedRegion: string): void {
    console.log('regionChanged', selectedRegion);
    setTimeout(() => {
      const foundRegion = this.provinceOrStateList.find((provinceOrState: any) => provinceOrState.name.toLowerCase() === selectedRegion.toLowerCase());
      if (foundRegion) {
        this.locationForm.get('stateOrProvince').setValue(foundRegion.code);
      }
    });
  }

  timezoneChanged(selectedTimezone: string): void {
    console.log('timezoneChanged', selectedTimezone);
    // const foundTimezone = this.timezoneList.forEach((timezone: any) => {
    //   return timezone.name.toLowerCase() === selectedTimezone.toLowerCase() || timezone.code.toLowerCase() === selectedTimezone.toLowerCase();
    // });
    // if (foundTimezone) {
    //   this.locationForm.get('timezone').setValue(foundTimezone.code);
    // }
  }

  showTimeZoneDropdown(): void {
    this.locationForm.addControl(
      'timezone', this.fb.control('', [Validators.required])
    );
    this.canShowTimeZoneDropdown = true;
  }

  private countryUsesRegions(country: string): boolean {
    const countryMeta = environment.countries.find((c) => c.code === country);
    return countryMeta.hasAddressRegions;
  }

  private checkBetaCountry(countryCode: string) {
    //this.alertSvc.dismiss();
    this.toastSvc.hideToast();
    const foundCountry = this.countryList.find((country: any) => {
      return country.name.toLowerCase() === countryCode.toLowerCase() || country.code.toLowerCase() === countryCode.toLowerCase();
    });

    if (foundCountry.status === 'BETA') {
      this.toastSvc.showToast(this.translateService.instant('warnings.unsupportedCountry'), 8000, 'warning');
    }
  }

  private setCountryRestrictions(countries: string[]) {
    if (this.autocomplete) {
      this.autocomplete.setComponentRestrictions({ country: countries });
    }
    else {
      console.warn('location-profile-form.setCountryRestrictions()... autocomplete not ready');
    }
  }
}
