import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { StorageItemName, StorageService } from '../../../services/storage-service.service';
import {
  takeWhile,
  concatMap,
  debounceTime,
  filter,
  switchMap,
  mergeMap,
  delay,
  finalize,
  throttleTime,
  map
} from 'rxjs/operators';
import { BaseDataComponent } from '../shared/base-data-component';
import { AdminService } from '../../../services/admin.service';
import { BillingService } from '../../../services/billing.service';
import { PageName } from '../../../shared/models/page-name.enum';
import { CarrierService } from '../../../services/carrier.service';
import { Order } from '@mitel/cloudlink-sdk/carrier';
import { of, fromEvent, bindCallback, timer, BehaviorSubject, Subject } from 'rxjs';
import { CarrierOrderStatus } from '../../../shared/models/carrier-order-status.enum';
import { ILocation } from '../../../shared/models/location.interface';
import { AccountTagKeys } from '../../../shared/models/user-tag-keys.enum';
import { LocationProfileFormComponent } from '../../../shared/components/location-profile-form/location-profile-form.component';
import { AnalyticsService } from '../../../services/analytics.service';
import { DigitalSignature, OrderCreateInfo } from '@mitel/cloudlink-sdk/billing';
import { ToastService } from 'src/app/services/toast.service';
import { UserType } from '../../../shared/models/user-type-enum';
import { ZuoraErrors } from '../../../shared/models/zuora-errors.enum';
import { TranslateService } from '@ngx-translate/core';
import { ZuoraUtils } from '../../../shared/utils/zuora-util';

declare var Z; // from Zuora library, imported in index.html

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent extends BaseDataComponent implements OnInit {
  storedSite;
  storedUser;
  invoice = 399.98;
  dueNow = 0.00;
  storedBusinessNumber: string;
  numUsers = 0;
  exitToDashboardAttempt = false;
  locationInfo: ILocation;
  billingAddress: ILocation;
  canShowEditLocationView = false;
  storedPaymentTerm: 'monthly' | 'annual';
  isZouraLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  redirectingToDashboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(LocationProfileFormComponent) locationProfileForm: LocationProfileFormComponent;
  private handleZuoraErrors: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService,
    private adminService: AdminService,
    private carrierService: CarrierService,
    private analyticsSrv: AnalyticsService,
    private storageService: StorageService,
    private billingService: BillingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) {
    super();
  }

  // Unsaved phone browser's native alert
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.exitToDashboardAttempt && (this.storedBusinessNumber !== null)) {
      $event.returnValue = true;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    // TO DO: make sure exists
    this.storedSite = this.storageService.getLocalStorageItem(StorageItemName.SITE);
    this.storedUser = this.storageService.getLocalStorageItem(StorageItemName.USER);
    this.storedBusinessNumber = this.storageService.getLocalStorageItem(StorageItemName.BUSINESS_NUMBER);
    this.storedPaymentTerm = this.storageService.getLocalStorageItem(StorageItemName.TERM);
    this.numUsers = this.storageService.getLocalStorageItem(StorageItemName.USERS) || 0;
    this.setLocation();
    this.adminService.createAccountTag(AccountTagKeys.LAST_PAGE, PageName.CARD)
      .pipe(takeWhile(() => this.active))
      .subscribe((createdTag) => console.log(createdTag, 'account tag created'));

    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        takeWhile(() => this.active),
        filter(event => event.key.startsWith('payment_method')),
        debounceTime(2000))
      .subscribe((event: any) => {
        console.log('storage event', event);
        if (event.key === 'payment_method_failed' && event.newValue) {
          const errorData = JSON.parse(event.newValue);
          console.error('payment method failed, re-rendering...', errorData);
          this.handleZuoraErrors.next(errorData.errorMessage);
          this.initializeCardForm();
        } else if (event.key === 'payment_method_success' && event.newValue) {
          console.log('payment method success, continuing...');
          this.continueAfterCard(event.newValue);
        }
      });

    this.handleZuoraErrors.pipe(
      takeWhile(() => this.active),
      throttleTime(500),
      map(error => {
        this.ngZone.run(() => {
          let errorMessage = this.translateService.instant('zuoraErrors.default');
          switch (error) {
            case ZuoraErrors.creditCardExpirationMonth:
              errorMessage = this.translateService.instant('zuoraErrors.' + ZuoraErrors.creditCardExpirationMonth);
              break;
            case ZuoraErrors.creditCardHolderName:
              errorMessage = this.translateService.instant('zuoraErrors.' + ZuoraErrors.creditCardHolderName);
              break;
            case ZuoraErrors.cardSecurityCode:
              errorMessage = this.translateService.instant('zuoraErrors.' + ZuoraErrors.cardSecurityCode);
              break;
            case ZuoraErrors.creditCardNumber:
              errorMessage = this.translateService.instant('zuoraErrors.' + ZuoraErrors.creditCardNumber);
              break;
            default:
              if (typeof error === 'string' && error.length) {
                errorMessage = error;
              }
              break;
          }
          this.isZouraLoading$.next(false);
          this.toastService.showToast(errorMessage, 8000, 'error');
        });

        return error;
      })
    ).subscribe((error) => console.error('Zuora: , error: ', error));

    this.initializeCardForm();
  }

  initializeCardForm(): void {
    this.isZouraLoading$.next(true);
    const observableRunAfterRender = bindCallback(Z.runAfterRender);

    this.billingService.generateSignature({
      family: 'galaxy',
      domain: window.location.href,
    }).pipe(
      takeWhile(() => this.active),
      concatMap((signature: DigitalSignature) => {
        const hpmParams = ZuoraUtils.getZuoraHpmParamsFromDigitalSignature(signature, window.navigator.language);
        const hpmFields = ZuoraUtils.getZuoraHpmFieldsFromLocationAndUser(this.storedSite.location, this.storedUser);
        this.ngZone.runOutsideAngular(() => {
          Z.renderWithErrorHandler({ ...hpmParams, retainValues: true }, hpmFields, response => {
            this.handleZuoraErrors.next(response);
          }, (key, _code, message) => {
            this.handleZuoraErrors.next(message);
            Z.sendErrorMessageToHpm(key, message);
          });

          Z.customizeErrorHandler((error) => {
            this.handleZuoraErrors.next(error);
          });
        });

        return observableRunAfterRender();
      }),
      finalize(() =>
        this.ngZone.run(() => {
          this.isZouraLoading$.next(false);
        })
      )
    ).subscribe(() => { }, (error: Error) => {
      console.error('failed to render hosted payment page', error);
      this.goToErrorPage();
    });
  }

  setLocation(): void {
    if (this.storedSite) {
      this.locationInfo = {
        companyName: this.storedSite.name,
        city: this.storedSite.location.address.city,
        country: this.storedSite.location.address.country,
        stateOrProvince: this.storedSite.location.address.stateOrProvince,
        address: this.storedSite.location.address.street,
        address2: this.storedSite.location.address.street2,
        zipOrPostalCode: this.storedSite.location.address.zipCode
      } as ILocation;
    }
  }

  continue(): void {
    this.toastService.hideToast();
    let canSave = true;
    this.billingAddress = Object.assign({}, this.locationInfo);
    if (this.locationProfileForm) {
      this.locationProfileForm.validate();
      canSave = this.locationProfileForm.isValid();
      this.billingAddress = this.locationProfileForm.getFormattedLocationData();
    }

    // clean up the address of the timezone and companyName
    delete this.billingAddress['companyName'];
    delete this.billingAddress['timezone'];

    console.log('billing address before submit => ', this.billingAddress);

    if (!canSave) {
      console.error('location is not valid?');
      return;
    }

    if (!this.storedBusinessNumber || !this.storedSite || !this.storedPaymentTerm) {
      console.error('no business number, site, or stored payment term, cannot proceed');
      this.goToErrorPage();
      return;
    }

    this.isZouraLoading$.next(true);
    const hpmFields = ZuoraUtils.getZuoraHpmFieldsFromILocation(this.billingAddress);
    const { creditCardState } = hpmFields;
    delete hpmFields.creditCardState;

    this.ngZone.runOutsideAngular(() => {
      timer(1).pipe(
        mergeMap((val) => {
          Z.prepopulate(hpmFields);
          return of(hpmFields);
        }),
        delay(100),
        mergeMap((val) => {
          Z.prepopulate({ creditCardState });
          return of({ ...val, ...{ creditCardState } });
        }),
        delay(100),
        mergeMap((val) => {
          Z.submit();
          return of(val);
        }),
      ).subscribe((val) => {
        console.log('Function: , submit: ', val);
      });
    });
  }

  continueAfterCard(paymentMethod: string): void {
    this.isZouraLoading$.next(true);
    console.log('billing address after submit => ', this.billingAddress);
    this.createBillingOrder(paymentMethod)
      .pipe(
        takeWhile(() => this.active),
        concatMap((orderCreateInfo: OrderCreateInfo) => {
          console.log('Subscribed for service', orderCreateInfo);
          return this.carrierService.createConnectOrder(
            this.storedUser.accountId,
            this.storedSite.siteId,
            [this.storedBusinessNumber]
          );
        }),
        concatMap((order: Order) => {
          console.log('after -> createConnectOrder', order);
          if (order.orderStatus === CarrierOrderStatus.complete) {
            return of(order);
          }
          return this.carrierService.getConnectOrder({
            accountId: order.accountId,
            siteId: order.siteId,
            orderId: order.orderId
          });
        }),
        concatMap((order: Order) => {
          console.log('after -> getConnectOrder', order);

          const body: any = {
            name: 'PSTN gateway',
            siteId: order.siteId,
            secureTrunking: false,
            originationOptions: {
              register: false,
              carrierName: order.carrierName
            },
            numbers: [{ 'pattern': order.orderNumbers[0] }]
          };
          return this.adminService.createGateway(this.storedUser.accountId, body);
        }),
        switchMap(() => {
          const params = {
            accountId: this.storedUser.accountId,
            userId: this.storedUser.uniqueUserId,
            body: {}
          };
          return this.adminService.sendWelcomeToUserByAccountId(params, UserType.admin);
        })
      )
      .subscribe(() => {
        this.exitToDashboardAttempt = true;
        this.redirectingToDashboard$.next(true);
        this.authService.routeToDashboard();
      },
        (error: any) => {
          console.log('failed to create subscription/order/number', error);
          this.goToErrorPage();
        });
  }

  private createBillingOrder(paymentMethod: string) {

    return this.billingService.createSubscription(this.numUsers + 1, paymentMethod, this.billingAddress,
      this.storedBusinessNumber, this.storedPaymentTerm);
  }

  modifyAddress(): void {
    this.canShowEditLocationView = true;
    this.analyticsSrv.recordCustomClick('modify-address-card-page');
  }

  goToErrorPage(): void {
    this.router.navigate(['/error']);
  }
}
