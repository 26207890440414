import { Component } from '@angular/core';

@Component({
  selector: 'instructions',
  templateUrl: './instructions.component.html',
  styleUrls: [ './instructions.component.scss' ]
})
export class InstructionsComponent {
  constructor() {
  }
}
