import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRouting } from './app.routes';
import { GetStartedModule } from './modules/get-started/get-started.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { VerifyCodeComponent } from './modules/verify-code/verify-code.component';
import { SpinnerComponent } from './modules/get-started/shared/spinner/spinner.component';
import {WebpackTranslateLoader} from './webpack-translate-loader';
import { WelcomeBackToastComponent } from './shared/components/welcome-back-toast/welcome-back-toast.component';

const bugsnagClient = bugsnag(environment.bugSnagKey);

export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient);
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    VerifyCodeComponent,
    WelcomeBackToastComponent
  ],
  entryComponents: [WelcomeBackToastComponent],
  imports: [
    AppRouting,
    BrowserModule,
    BrowserAnimationsModule,
    GetStartedModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: (WebpackTranslateLoader)
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlacesKey,
      libraries: ['places']
    })
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
