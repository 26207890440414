import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'lang-switch',
  templateUrl: './lang-switch.component.html',
  styleUrls: ['./lang-switch.component.scss']
})
export class LangSwitchComponent implements OnInit {

  switchLang: string;

  constructor(public translateService: TranslateService) { }

  ngOnInit() {

    if (!this.isDefaultLang()) {
      this.switchLang = 'en';
    }
  }

  isDefaultLang() {
    return this.translateService.defaultLang === environment.defaultLang;
  }

  setLang(lang) {

    this.switchLang = this.translateService.currentLang;
    this.translateService.use(lang);
  }

}
