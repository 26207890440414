
const postCode = require('postcode-validator');
import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

const EMAIL_REGEX = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export function emailValidator(control: FormControl): { [key: string]: boolean } | null {
  const email = control.value;
  const invalidStatus = { 'invalid': true };
  if (!email) {
    return null;
  }

  if (email.length > 256) {
    return invalidStatus;
  }

  if (!EMAIL_REGEX.test(email)) {
    return invalidStatus;
  }

  const parts = email.split('@');
  if (parts[0].length > 64) {
    return invalidStatus;
  }

  const domainParts = parts[1].split('.');
  if (domainParts.some((part: string) => part.length > 63)) {
    return invalidStatus;
  }
  return null;
}

export function zipCodeValidator(control: FormControl): { [key: string]: boolean } | null {
  const postalCode = control.value;
  if (postalCode) {
    if (!postCode.validate(postalCode, 'US')) {
      return { 'invalid': true };
    }
  }
  return null;
}

export function postalCodeValidator(control: FormControl): { [key: string]: boolean } | null {
  const postalCode = control.value;
  if (postalCode) {
    if (!postCode.validate(postalCode, 'CA')) {
      return { 'invalid': true };
    }
  }
  return null;
}

export function postalAndZipCodeValidator(form: FormGroup): { [key: string]: boolean } | null {

  const countryControl = form.get('country');
  const postalCodeControl = form.get('zipOrPostalCode');

  if (countryControl && postalCodeControl) {
    const country = countryControl.value === 'GB' ? 'UK' : countryControl.value; // Fix up this GB / UK hack
    const postalCode = postalCodeControl.value;
    if (postalCode) {
      if (!postCode.validate(postalCode, country)) {
        form.markAsTouched();
        // need to do this as we are validating multiple controls here. must set manually as this is not directly bound
        postalCodeControl.setErrors({ error: "invalid" });
        return { 'invalid': true };
      }
    }
    return null;
  }
  else {
    return null;
  }
}

export function duplicateEmailValidator(): ValidatorFn {

  return (formGroup: FormGroup): null => {
    const users = <FormArray>formGroup.controls['users'];

    if (users.controls.length !== 1) {
      users.controls.forEach((user: FormGroup) => {
        if (user.controls['email'].hasError('duplicateEmail')) {
          user.controls['email'].setErrors({ duplicateEmail: false });
        }
      });

      for (let i = 0; i < users.length - 1; i++) {
        for (let j = i + 1; j < users.length; j++) {
          const iControlValue = users.controls[i]['controls']['email']['value'] ? users.controls[i]['controls']['email']['value'].trim().toLowerCase() : '';
          const jControlValue = users.controls[j]['controls']['email']['value'] ? users.controls[j]['controls']['email']['value'].trim().toLowerCase() : '';
          if (jControlValue && jControlValue) {
            if (iControlValue === jControlValue) {
              if (!(users.controls[i]['controls']['email'].hasError('email') || users.controls[i]['controls']['email'].hasError('required'))) {
                users.controls[j]['controls']['email'].setErrors({ duplicateEmail: true });
              }
            }
          }
        }
      }
    }

    return null;
  };
}
