import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { RegistrationService, UserDetails } from '../../../services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, switchMap, takeWhile } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { User } from '@mitel/cloudlink-sdk';
import { StorageItemName, StorageService } from '../../../services/storage-service.service';
import { of } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { BaseDataComponent } from '../shared/base-data-component';
import { spinnerPipe } from '../../../shared/utils/spinner-pipe.operator';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { emailValidator } from '../../../shared/custom-validators';
import { environment } from '../../../../environments/environment';
import { FORBIDDEN } from 'http-status-codes';

@Component({
  selector: 'verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent extends BaseDataComponent implements OnInit {


  temporaryAccessCode: FormControl = new FormControl('');
  codeReceived = true;
  email: FormControl = new FormControl('');
  user: UserDetails;
  submitted = false;
  sendingAccessCode = false;

  constructor(private adminService: AdminService,
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storageService: StorageService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.storageService.getLocalStorageItem(StorageItemName.USER);
    this.temporaryAccessCode.setValidators([Validators.required]);
    this.email.setValue((this.user && this.user.email) ? this.user.email : '');
    this.email.setValidators([Validators.required, emailValidator]);
  }

  setCodeReceived(value: boolean): void {
    this.codeReceived = value;
  }

  createNewUser(): void {
    // Reuse name and password from step one
    const newUser = Object.assign(new UserDetails(), {
      name: this.user.name,
      email: this.email.value.toLowerCase(),
      password: this.user.password
    });

    this.registrationService.registerUser(newUser)
      .pipe(
        takeWhile(() => this.active)
      )
      .subscribe(
        () => {
          this.sendingAccessCode = false;
          this.toastService.showToast(this.translateService.instant('getStarted.verification.toastMessages.accessCodeSent'));
          this.user = this.registrationService.user;
          // Update local storage with new user data
          this.storageService.setLocalStorageItem(StorageItemName.USER, newUser);
        },
        err => {
          this.sendingAccessCode = false;
        }
      );
  }

  resendAccessCode(): void {
    this.email.markAsTouched();
    if (this.email.invalid) {
      return;
    }
    this.sendingAccessCode = true;
    // Create new user if new email entered
    if (this.user.email !== this.email.value) {
      this.createNewUser();
    } else {
      this.sendAccessCode();
    }
  }

  sendAccessCode(): void {
    this.adminService.sendAccessCode(this.user.uniqueUserId)
      .subscribe(
        () => {
          this.sendingAccessCode = false;
          this.toastService.showToast(this.translateService.instant('getStarted.verification.toastMessages.accessCodeSent'));
        },
        err => {
          this.sendingAccessCode = false;
        }
      );
  }

  verify() {
    this.submitted = true;
    this.temporaryAccessCode.markAsTouched();
    if (this.temporaryAccessCode.invalid) {
      return;
    }

    this.adminService.verifyAccessCode(this.user.uniqueUserId, this.temporaryAccessCode.value)
      .pipe(
        takeWhile(() => this.active),
        filter(user => user.emailVerified),
        switchMap((user: User) => {
          // Set the verified from the response as it should be true
          this.user.emailVerified = user.emailVerified;
          const params = {
            user: {
              username: this.user.uniqueUserId,
              password: this.user.password,
              clientId: environment.clientId
            }
          };
          return this.authService.login(params);
        }),
        catchError((error) => {
          if (error.statusCode === FORBIDDEN) {
            this.temporaryAccessCode.setErrors({ invalidCode: true });
          }
          return of(null);
        }),
        spinnerPipe(this.spinnerService)
      ).subscribe((response) => {
        if (response !== null) {
          console.log(`${this.constructor.name} --> Login Successful, navigating to location`);

          this.registrationService.user = this.user;
          this.storageService.setLocalStorageItem(StorageItemName.USER, Object.assign({}, this.user));
          this.router.navigate(['location'], { relativeTo: this.route.parent });
        }
      });
  }
}
