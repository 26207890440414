import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { concatMap , takeWhile } from 'rxjs/operators';
import { BaseDataComponent } from '../../../modules/get-started/shared/base-data-component';
import { StorageItemName , StorageService } from '../../../services/storage-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'welcome-back-toast',
  templateUrl: './welcome-back-toast.component.html',
  styleUrls: ['./welcome-back-toast.component.scss']
})
export class WelcomeBackToastComponent extends BaseDataComponent implements OnInit{

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  logoutUserAndRedirectToSignup(): void {
    this.authService.logoutUser()
      .pipe(
        takeWhile(() =>  this.active),
        concatMap(() => this.storageService.clearLocalStorage([StorageItemName.MARKETING_INFO, StorageItemName.TERM]))
      ).subscribe(() => this.router.navigateByUrl('getStarted/signup'));
  }

}
