import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StorageItemName, StorageService } from './storage-service.service';
import { AdminService } from './admin.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserCollection } from '@mitel/cloudlink-sdk/admin';
import { MAX_NUMBER_OF_INVITES } from '../modules/get-started/invite/invite.component';
import { User } from '@mitel/cloudlink-sdk';

@Injectable()
export class InvitedUsersResolver implements Resolve<any> {
  constructor(
    private storageService: StorageService,
    private adminService: AdminService
  ) {

  }

  resolve(): Observable<any> {
    return this.adminService.getUsers({$Top: MAX_NUMBER_OF_INVITES})
      .pipe(
        map((userCollection: UserCollection) => {
          const invitedUsersArray = userCollection['_embedded']['items'];
          if ( invitedUsersArray && invitedUsersArray.length > 0) {
            return invitedUsersArray;
          } else {
            return null;
          }
        }),
        map((invitedUsers: any[]) => {
          const currentUser = this.storageService.getLocalStorageItem(StorageItemName.USER);
          if (currentUser) {
            return invitedUsers.filter((user: User) => user.uniqueUserId !== currentUser.userId);
          }
          return invitedUsers;
        }),
        catchError(() => of(null))
      );
    }
}
