import { Injectable } from '@angular/core';
import {
  CarrierService as CloudCarrier,
  Order,
  PhoneNumbersCollection,
  RateCentersCollection,
  Site as CarrierSite
} from '@mitel/cloudlink-sdk/carrier';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { LocationService } from './location.service';
import { TextUtils } from '../shared/utils/text-utils';
import { Site } from '@mitel/cloudlink-sdk';
import { PhoneNumber, RateCenter } from '@mitel/cloudlink-sdk/carrier/carrier-service.g';
import { map } from 'rxjs/operators';
import { CountryCode } from 'libphonenumber-js';

export interface IFilterParams {
  countryCode: string;
  stateOrProvince: string;
  city?: string;
  areaCode?: string;
  tollFree: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  private readonly cloudCarrierApi: CloudCarrier;
  private cachedCarrierSite: CarrierSite;

  constructor(
    private adminService: AdminService,
    private locationService: LocationService
  ) {

    this.cloudCarrierApi = new CloudCarrier();
  }

  getSite(params: {
    accountId: string;
    siteId: string;
    options?: any;
  }, fromCache = true): Observable<CarrierSite> {
    if (fromCache && this.cachedCarrierSite) {
      console.log('Using cached Carrier Site info for --> ', params);
      return of(this.cachedCarrierSite);
    }
    console.log('Getting Carrier Site info for --> ', params);
    return from(this.cloudCarrierApi.getSite(params))
      .pipe(
        map((carrierSite: CarrierSite) => {
          this.cachedCarrierSite = carrierSite;
          return carrierSite;
        }));
  }

  getAvailablePhoneNumbers(params: {
    carrierName: string;
    $Top?: number;
    $Filter?: string;
    options?: any;
  }): Observable<PhoneNumbersCollection> {
    console.log('Getting available numbers for --> ', params);
    return from(this.cloudCarrierApi.getAvailablePhoneNumbers(params));
  }

  createConnectOrder(accountId: string, siteId: string, businessNumbers: string[]): Observable<Order> {
    const params: any = {
      accountId: accountId,
      siteId: siteId,
      body: {
        orderNumbers: businessNumbers.map((number: string) => TextUtils.formatPhoneNumberToE164(number))
      }
    };
    return from(this.cloudCarrierApi.createConnectOrder(params));
  }

  getConnectOrder(params: {
    accountId: string;
    siteId: string;
    orderId: string;
    options?: any;
  }): Observable<Order> {
    console.log('Making a connect order for --> ', params);
    return from(this.cloudCarrierApi.getConnectOrder(params));
  }

  getNumbersFromSite(site: Site, maxNumbers: number, tollFree: boolean = false): Observable<PhoneNumbersCollection> {
    console.log('getNumbersFromSite -> ', site);

    const { address } = site.location;

    return this.getSite({ accountId: site.accountId, siteId: site.siteId })
      .pipe(
        switchMap((carrierSite: CarrierSite) => {

          const filter = this.generateOdataFilter({
            countryCode: address.country,
            stateOrProvince: address.stateOrProvince,
            city: address.city,
            tollFree: tollFree
          } as IFilterParams);

          const params = {
            carrierName: carrierSite.carrierName,
            $Top: maxNumbers,
            $Filter: filter
          };
          return this.getAvailablePhoneNumbers(params);
        }),
        switchMap((phoneNumbersCollection: PhoneNumbersCollection) => this.collectNumbers(phoneNumbersCollection, address.country as CountryCode))
      );
  }

  searchNumbersByCity(site: Site, city: string, maxNumbers: number): Observable<any> {
    console.log('searchNumbersByCity -> ', city, maxNumbers);

    const { address } = site.location;
    return this.getSite({ accountId: site.accountId, siteId: site.siteId })
      .pipe(
        switchMap((carrierSite: CarrierSite) => {

          const filter: string = this.generateOdataFilter({
            countryCode: address.country,
            stateOrProvince: address.stateOrProvince,
            city: city
          } as IFilterParams);

          const params = {
            carrierName: carrierSite.carrierName,
            $Top: maxNumbers,
            $Filter: filter
          };
          return this.getAvailablePhoneNumbers(params);
        }),
        switchMap((phoneNumbersCollection: PhoneNumbersCollection) => this.collectNumbers(phoneNumbersCollection, address.country as CountryCode))
      );
  }

  searchNumbersByAreaCode(site: Site, areaCode: string, maxNumbers: number): Observable<any> {
    console.log('searchNumbersByAreaCode -> ', areaCode, maxNumbers);

    const { address } = site.location;

    return this.getSite({ accountId: site.accountId, siteId: site.siteId })
      .pipe(
        switchMap((carrierSite: CarrierSite) => {
          const filter = this.generateOdataFilter({
            countryCode: address.country,
            stateOrProvince: address.stateOrProvince,
            areaCode: areaCode
          } as IFilterParams);

          const params = {
            carrierName: carrierSite.carrierName,
            $Top: maxNumbers,
            $Filter: filter
          };
          return this.getAvailablePhoneNumbers(params);
        }),
        switchMap((phoneNumbersCollection: PhoneNumbersCollection) => this.collectNumbers(phoneNumbersCollection, address.country as CountryCode))
      );
  }

  private generateOdataFilter(filterParams: IFilterParams): string {
    let query = '';
    if (filterParams.tollFree) {
      query = `type eq 'tollfree'`;
      return query;
    }
    else {
      query = `type eq 'local'`;
    }

    if (filterParams.countryCode) {
      if (query) {
        query += ' and ';
      }
      query += `countryCode eq '${filterParams.countryCode.toUpperCase()}'`;
    }

    // e.g.: "type eq 'tollfree' and substringof('^88*', number)" if more filtering is required
    if (filterParams.countryCode && filterParams.stateOrProvince) {
      const foundProvinceOrState = this.locationService.getProvinceOrState(filterParams.countryCode, filterParams.stateOrProvince);
      if (foundProvinceOrState) {

        if (query) {
          query += ' and ';
        }

        query = `region eq '${foundProvinceOrState['code']}'`;
      }
    }

    if (filterParams.city) {
      if (query) {
        query += ' and ';
      }
      query += `city eq '${filterParams.city.toUpperCase()}'`;
    }

    if (filterParams.areaCode) {
      if (query) {
        query += ' and ';
      }
      query += `areaCode eq '${filterParams.areaCode}'`;
    }
    return query;
  }

  collectNumbers(phoneNumbersCollection: PhoneNumbersCollection, countryCode: CountryCode = 'US'): Observable<any> {
    const numbers: string[] = [];
    console.log('phoneNumbersCollection', phoneNumbersCollection);
    if (phoneNumbersCollection && phoneNumbersCollection._embedded) {
      phoneNumbersCollection._embedded.items.forEach((item: PhoneNumber) => {
        const phoneNumber: string = TextUtils.normalizePhoneNumber(item.number, countryCode);
        if (phoneNumber) {
          numbers.push(phoneNumber);
        }
      });
    }
    return of(numbers);
  }

  getRateCenters(site: Site, city?: string, areaCode?: string, maxNumbers: number = 20): Observable<any> {
    return this.getSite({ accountId: site.accountId, siteId: site.siteId })
      .pipe(
        switchMap((carrierSite: CarrierSite) => {
          const { address } = site.location;
          const filter = this.generateOdataFilter({
            city: city,
            areaCode: areaCode
          } as IFilterParams);

          console.log(filter);

          const params: any = {
            carrierName: carrierSite.carrierName,
            region: address.stateOrProvince,
            $Top: maxNumbers,
            $Filter: filter
          };
          return from(this.cloudCarrierApi.getRateCenters(params));
        }),
        switchMap((rateCenters: RateCentersCollection) => {
          const areaCodeMappings = new Set();
          if (rateCenters.count) {
            rateCenters._embedded.items.forEach((rateCenter: RateCenter) => {
              console.log(rateCenter);
              rateCenter.areaCodes.forEach((aCode: number) => {
                areaCodeMappings.add(`${aCode}`);
              });
            });
          }
          console.log('areaCodeMappings', areaCodeMappings);
          return of(Array.from(areaCodeMappings));
        })
      );
  }
}
