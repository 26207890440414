import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import * as contentEn from '../assets/i18n/en.json';
import * as contentDe from '../assets/i18n/de.json';
import * as contentFr from '../assets/i18n/fr.json';
import * as contentNl from '../assets/i18n/nl.json';
import * as contentZh from '../assets/i18n/zh.json';

const TRANSLATIONS = {
  en: contentEn,
  fr: contentFr,
  de: contentDe,
  nl: contentNl,
  zh: contentZh
};

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }

  public static getSupportedLangs() {
    return Object.keys(TRANSLATIONS);
  }
}
