import { Injectable } from '@angular/core';
import { catchError, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GeocodeResponseObject } from '../shared/models/geocode-response.interface';
import { GoogleGeocodingService } from './google-geocoding.service';
import * as momentTimezone from 'moment-timezone';
import { TextUtils } from '../shared/utils/text-utils';
import { environment } from '../../environments/environment';
const ct = require('countries-and-timezones');
const tzlookup = require('tz-lookup');

const abbrs = {
  EST: 'Eastern Time',
  EDT: 'Eastern Time',
  CST: 'Central Time',
  CDT: 'Central Time',
  MST: 'Mountain Time',
  MDT: 'Mountain Time',
  PST: 'Pacific Time',
  PDT: 'Pacific Time',
  AKDT: 'Alaska Time',
  HDT: 'Hawaii–Aleutian Time',
  HST: 'Hawaii–Aleutian Time',
  ADT: 'Atlantic Time',
  NDT: 'Newfoundland Time',
  AST: 'Atlantic Time',
  GMT: 'Greenwich Mean Time',
  BST: 'British Summer Time'
};

momentTimezone.fn.zoneName = function () {
  const abbr = this.zoneAbbr();
  return abbrs[abbr] || abbr;
};

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private _countryList: any[] = [];

  private _provinceOrStateList: any = {
    'CA': [
      { 'name': 'Alberta', 'code': 'AB' },
      { 'name': 'British Columbia', 'code': 'BC' },
      { 'name': 'Manitoba', 'code': 'MB' },
      { 'name': 'New Brunswick', 'code': 'NB' },
      { 'name': 'Newfoundland and Labrador', 'code': 'NL' },
      { 'name': 'Northwest Territories', 'code': 'NT' },
      { 'name': 'Nova Scotia', 'code': 'NS' },
      { 'name': 'Nunavut', 'code': 'NU' },
      { 'name': 'Ontario', 'code': 'ON' },
      { 'name': 'Prince Edward Island', 'code': 'PE' },
      { 'name': 'Quebec', 'code': 'QC' },
      { 'name': 'Saskatchewan', 'code': 'SK' },
      { 'name': 'Yukon Territory', 'code': 'YT' }
    ],
    'US': [
      { 'name': 'Alabama', 'code': 'AL' },
      { 'name': 'Alaska', 'code': 'AK' },
      { 'name': 'American Samoa', 'code': 'AS' },
      { 'name': 'Arizona', 'code': 'AZ' },
      { 'name': 'Arkansas', 'code': 'AR' },
      { 'name': 'California', 'code': 'CA' },
      { 'name': 'Colorado', 'code': 'CO' },
      { 'name': 'Connecticut', 'code': 'CT' },
      { 'name': 'Delaware', 'code': 'DE' },
      { 'name': 'Washington, DC', 'code': 'DC' },
      // { 'name': 'Federated States Of Micronesia', 'code': 'FM' },
      { 'name': 'Florida', 'code': 'FL' },
      { 'name': 'Georgia', 'code': 'GA' },
      { 'name': 'Guam', 'code': 'GU' },
      { 'name': 'Hawaii', 'code': 'HI' },
      { 'name': 'Idaho', 'code': 'ID' },
      { 'name': 'Illinois', 'code': 'IL' },
      { 'name': 'Indiana', 'code': 'IN' },
      { 'name': 'Iowa', 'code': 'IA' },
      { 'name': 'Kansas', 'code': 'KS' },
      { 'name': 'Kentucky', 'code': 'KY' },
      { 'name': 'Louisiana', 'code': 'LA' },
      { 'name': 'Maine', 'code': 'ME' },
      { 'name': 'Marshall Islands', 'code': 'MH' },
      { 'name': 'Maryland', 'code': 'MD' },
      { 'name': 'Massachusetts', 'code': 'MA' },
      { 'name': 'Michigan', 'code': 'MI' },
      { 'name': 'Minnesota', 'code': 'MN' },
      { 'name': 'Mississippi', 'code': 'MS' },
      { 'name': 'Missouri', 'code': 'MO' },
      { 'name': 'Montana', 'code': 'MT' },
      { 'name': 'Nebraska', 'code': 'NE' },
      { 'name': 'Nevada', 'code': 'NV' },
      { 'name': 'New Hampshire', 'code': 'NH' },
      { 'name': 'New Jersey', 'code': 'NJ' },
      { 'name': 'New Mexico', 'code': 'NM' },
      { 'name': 'New York', 'code': 'NY' },
      { 'name': 'North Carolina', 'code': 'NC' },
      { 'name': 'North Dakota', 'code': 'ND' },
      { 'name': 'Northern Mariana Islands', 'code': 'MP' },
      { 'name': 'Ohio', 'code': 'OH' },
      { 'name': 'Oklahoma', 'code': 'OK' },
      { 'name': 'Oregon', 'code': 'OR' },
      { 'name': 'Palau', 'code': 'PW' },
      { 'name': 'Pennsylvania', 'code': 'PA' },
      { 'name': 'Puerto Rico', 'code': 'PR' },
      { 'name': 'Rhode Island', 'code': 'RI' },
      { 'name': 'South Carolina', 'code': 'SC' },
      { 'name': 'South Dakota', 'code': 'SD' },
      { 'name': 'Tennessee', 'code': 'TN' },
      { 'name': 'Texas', 'code': 'TX' },
      { 'name': 'Utah', 'code': 'UT' },
      { 'name': 'Vermont', 'code': 'VT' },
      { 'name': 'Virgin Islands', 'code': 'VI' },
      { 'name': 'Virginia', 'code': 'VA' },
      { 'name': 'Washington', 'code': 'WA' },
      { 'name': 'West Virginia', 'code': 'WV' },
      { 'name': 'Wisconsin', 'code': 'WI' },
      { 'name': 'Wyoming', 'code': 'WY' }
    ]
  };

  //private _timezoneList: any = {};
  private _timezoneGroupList: any = {};

  constructor(
    private geocodeService: GoogleGeocodingService
  ) {

    if (environment.allowBetaCountries) {
      this._countryList = environment.countries;
    }
    else {
      this._countryList = environment.countries.filter((c) => c.status === 'PROD');
    }

    this.initializeTimezoneList();
  }

  initializeTimezoneList(): void {
    this._countryList.forEach((country: any) => {
      const tzs = ct.getTimezonesForCountry(country.code);
      const countryTzMap = tzs.map((tz: any) => {
        const tzName = momentTimezone().tz(tz.name).format('zz');
        return {
          code: tz.name,
          name: `UTC ${tz.offsetStr} ${tzName} (${this.getTimezoneSegment(tz.name)})`,
        };
      });
      console.log('countryTzMap', countryTzMap);
      this._timezoneGroupList[country.code] = countryTzMap;
    });
    console.log(this._timezoneGroupList);
  }

  getCountryList(): any[] {
    return this._countryList;
  }

  getProvinceOrStateList(countryCode: string): any[] {
    return this._provinceOrStateList[countryCode] ? this._provinceOrStateList[countryCode] : [];
  }

  getProvinceOrState(countryCode: string, stateOrProvince: string): string {
    return this.getProvinceOrStateList(countryCode).find((provinceOrStateItem: any) => {
      return provinceOrStateItem.code.toLowerCase() === stateOrProvince.toLowerCase();
    });
  }

  getCountryCode(fullCountryName: string): string {
    return this._countryList.find((countryItem: any) => {
      return (countryItem.name.toLowerCase() === fullCountryName.toLowerCase());
    })['code'];
  }

  getTimezoneList(country: string): any[] {
    console.log('location.service.getTimezoneList()', country);
    return this._timezoneGroupList[country] || {};
  }

  getTimezoneFromLocation(locationParams: any): Observable<any> {
    return this.geocodeService.getLatLongFromLocation(
      locationParams.city,
      locationParams.stateOrProvince,
      locationParams.country)
      .pipe(
        flatMap((geocodeResponse: GeocodeResponseObject) => {
          console.log('geocodeResponse', geocodeResponse);
          if (geocodeResponse.status === 'OK') {
            if (geocodeResponse.results
              && geocodeResponse.results.length
              && !geocodeResponse.results[0].partial_match
            ) {
              const lat = geocodeResponse.results[0].geometry.location.lat;
              const lng = geocodeResponse.results[0].geometry.location.lng;
              return of(tzlookup(lat, lng));
            }
          }
          return of('');
        }),
        catchError((error) => of(''))
      );
  }

  private getTimezoneSegment(str: string): string {
    return TextUtils.stripUnderscore(str.substring(str.lastIndexOf('/') + 1, str.length));
  }
}
