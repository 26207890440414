import { Injectable } from '@angular/core';
import {
  BillingService as CloudBilling, AccountCreateRequest, BillingAccount,
  DigitalSignatureRequest, DigitalSignature, OrderCreateInfo, OrderCreateRequest,
  RatePlanOverrideActionRequest, HpmParams, HpmFields
} from '@mitel/cloudlink-sdk/billing';
import { User, Config } from '@mitel/cloudlink-sdk';
import { from, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ILocation } from '../shared/models/location.interface';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private readonly api: CloudBilling;
  private readonly applicationIdHeader: any = {
    headers: {
      'x-mitel-application-id': environment.clientId
    }
  };

  /*
  public static getZuoraHpmParamsFromDigitalSignature(signature: DigitalSignature, locale: string): HpmParams {
    return CloudBilling.getZuoraHpmParamsFromDigitalSignature(signature, locale);
  }

  public static getZuoraHpmFieldsFromLocationAndUser(location: Location, user: User): HpmFields {
    return CloudBilling.getZuoraHpmFieldsFromLocationAndUser(location, user);
  }

  public static getZuoraHpmFieldsFromILocation(location: ILocation): HpmFields {
    return CloudBilling.getZuoraHpmFieldsFromILocation(location);
  }*/

  constructor() {
    //const cloud = RouteUtils.isDevHost(location.hostname) ? 'dev' : 'public';
    //Config.cloud = cloud;
    this.api = new CloudBilling({
      version: '2019-03-01'
    });
  }

  public createBillingAccount(body: AccountCreateRequest): Observable<BillingAccount> {
    console.log(`${this.constructor.name}->createBillingAccount: AccountCreateRequest`, body);
    return from(this.api.createBillingAccount({ body }));
  }

  public generateSignature(body: DigitalSignatureRequest): Observable<DigitalSignature> {
    console.log(`${this.constructor.name}->generateSignature: DigitalSignatureRequest`, body);
    return from(this.api.generateSignature({ body }));
  }

  public createSubscription(
    users: number,
    hpmCreditCardPaymentMethodId: string,
    location: ILocation,
    phoneNumber: string,
    term: 'annual' | 'monthly'): Observable<OrderCreateInfo> {

    const periodType: 'Month' | 'Year' = term === 'monthly' ? 'Month' : 'Year';
    let ratePlanRequest: Array<RatePlanOverrideActionRequest>;

    if (term === 'monthly') {
      ratePlanRequest = [
        {
          productRatePlanId: Config.cloud.match(/dev/) ? '2c92c0f8690ed96f016913386c6152a0' : '2c92a0086a07f4aa016a124cb9c8681d',
          chargeOverrides: [{
            productRatePlanChargeId: Config.cloud.match(/dev/) ? '2c92c0f96958172e01695b7f9cce050a' : '2c92a0076a07e3bf016a12b7c4b618c9',
            pricing: {
              recurringVolume: {
                quantity: users
              }
            }
          }]
        },
        {
          productRatePlanId: Config.cloud.match(/dev/) ? '2c92c0f86875b19e01687d41875d2e3a' : '2c92a0086a07f4aa016a124cb9f0682e',
          chargeOverrides: [{
            productRatePlanChargeId: Config.cloud.match(/dev/) ? '2c92c0f86875b19701687d4261b24d91' : '2c92a0076a07e3bf016a12b7c53118df',
          }]
        },
        {
          productRatePlanId: Config.cloud.match(/dev/) ? '2c92c0f8697a269001697dfdea1b4cf9' : '2c92a0086a07f4aa016a124cb9da6825',
          chargeOverrides: [{
            productRatePlanChargeId: Config.cloud.match(/dev/) ? '2c92c0f9697a317901697dfee37d41e3' : '2c92a0076a07e3bf016a12b7c4f518d6',
          }]
        }
      ];
    } else {
      ratePlanRequest = [
        {
          productRatePlanId: Config.cloud.match(/dev/) ? '2c92c0f8697a269001697f54cbda6aba' : '2c92a0086a07f4aa016a124cb9f96833',
          chargeOverrides: [{
            productRatePlanChargeId: Config.cloud.match(/dev/) ? '2c92c0f9697a317901697f5a31ca6b2d' : '2c92a0076a07e3bf016a12b7c55518e5',
            pricing: {
              recurringVolume: {
                quantity: users
              }
            }
          }]
        },
        {
          productRatePlanId: Config.cloud.match(/dev/) ? '2c92c0f8697a269001697f664f483bb8' : '2c92a0086a07f4aa016a124cba026838',
          chargeOverrides: [{
            productRatePlanChargeId: Config.cloud.match(/dev/) ? '2c92c0f9697a317701697f68263636ee' : '2c92a0076a07e3bf016a12b7c58118ed',
          }]
        },
        {
          productRatePlanId: Config.cloud.match(/dev/) ? '2c92c0f8697a269001697dfdea1b4cf9' : '2c92a0086a07f4aa016a124cb9da6825',
          chargeOverrides: [{
            productRatePlanChargeId: Config.cloud.match(/dev/) ? '2c92c0f9697a317901697dfee37d41e3' : '2c92a0076a07e3bf016a12b7c4f518d6',
          }]
        }
      ];
    }

    const body: OrderCreateRequest = {
      newAccount: {
        family: 'galaxy',
        hpmCreditCardPaymentMethodId,
        billToContact: <any>{
          country: location.country,
          address1: location.address,
          address2: location.address2,
          city: location.city,
          state: location.stateOrProvince,
          zipCode: location.zipOrPostalCode,
          workPhone: phoneNumber
        }
      },
      subscriptions: [{
        orderActions: [{
          type: 'CreateSubscription',
          createSubscription: {
            terms: {
              initialTerm: {
                period: term === 'monthly' ? 2 : 1,
                periodType
              },
              renewalTerms: [{
                period: 1,
                periodType
              }]
            },
            subscribeToRatePlans: ratePlanRequest
          }
        }]
      }]
    };
    console.log(`${this.constructor.name}->createSubscription: OrderCreateRequest`, body);
    return from(this.api.createOrder({ body }));
  }
}
