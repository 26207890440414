import { Injectable } from '@angular/core';
import { ActivatedRoute , ActivatedRouteSnapshot , CanActivateChild , Router , RouterStateSnapshot } from '@angular/router';
import { Observable , of } from 'rxjs';
import { StorageItemName , StorageService } from '../services/storage-service.service';
import { UserDetails } from '../services/registration.service';
import { AuthService } from '../services/auth.service';
import { concatMap } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { WelcomeBackToastComponent } from '../shared/components/welcome-back-toast/welcome-back-toast.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private authService: AuthService,
    private toastService: ToastService
  ) {

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {

    if ( route['queryParams']['authCode'] ) {
        this.authService.authCode = route.queryParams['authCode'];
        return this.authService.loginUsingAuthCode().pipe(concatMap((loginResponse) => {
          if ( loginResponse && this.authService.authCode ) {
            this.toastService.openFromComponent(WelcomeBackToastComponent, 5000);
            this.authService.authCode = null;
          }
          return of(loginResponse);
        }));
    } else {
        const userDetails: UserDetails = this.storageService.getLocalStorageItem(StorageItemName.USER);

        if ( state.url.indexOf('/getStarted/signup') > -1 ) {
          if ( userDetails && userDetails.emailVerified === false ) {
            this.router.navigateByUrl('getStarted/verification');
            return false;
          }
          return true;
        } else if ( state.url === '/getStarted/verification' && userDetails && userDetails.emailVerified === false ) {
          return true;
        } else if ( userDetails === null ) {
          this.router.navigateByUrl('getStarted/signup');
          return false;
        } else if ( userDetails && userDetails.emailVerified === false ) {
          this.router.navigateByUrl('getStarted/verification');
          return false;
        } else {
          return true;
        }
    }
  }
}
