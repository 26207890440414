import { Component , OnInit , ViewChild } from '@angular/core';
import { ActivatedRoute , Router } from '@angular/router';
import { RegistrationCompany , RegistrationService } from '../../../services/registration.service';
import { AdminService } from '../../../services/admin.service';
import { AccountPutRequest , User } from '@mitel/cloudlink-sdk/admin';
import { SpinnerService } from '../../../services/spinner.service';
import { BaseDataComponent } from '../shared/base-data-component';
import { catchError , concatMap , filter , flatMap , switchMap , takeWhile, map } from 'rxjs/operators';
import { spinnerPipe } from '../../../shared/utils/spinner-pipe.operator';
import { StorageItemName , StorageService } from '../../../services/storage-service.service';
import { LocationProfileFormComponent } from '../../../shared/components/location-profile-form/location-profile-form.component';
import { PageName } from '../../../shared/models/page-name.enum';
import { ILocation } from '../../../shared/models/location.interface';
import { Site } from '@mitel/cloudlink-sdk';
import { HttpHeaders } from '../../../../../node_modules/@angular/common/http';
import { LocationService } from '../../../services/location.service';
import { GoogleSsoService } from '../../../services/google-sso.service';
import { Location } from '@mitel/cloudlink-sdk/admin/admin-service.g';
import { SsoSource } from '../../../shared/models/sso-source.enum';
import { Observable , of , zip } from 'rxjs';
import { AccountTagKeys } from '../../../shared/models/user-tag-keys.enum';
import { AnalyticsService } from '../../../services/analytics.service';
import { GoogleGeocodingService } from '../../../services/google-geocoding.service';
import { GeocodeResponseObject } from '../../../shared/models/geocode-response.interface';
import { FacebookSsoService } from '../../../services/facebook-sso.service';
import { AccountResponse } from '../../../shared/models/facebook-account-response.interface';
const tzlookup = require('tz-lookup');


@Component({
  selector: 'app-location' ,
  templateUrl: './location.component.html' ,
  styleUrls: [ './location.component.scss' ]
})

export class LocationComponent extends BaseDataComponent implements OnInit {
  @ViewChild(LocationProfileFormComponent) locationProfileForm: LocationProfileFormComponent;

  hasSavedLocation = false;
  locationData: ILocation;
  locationProfileFormView = true;
  ssoSource: string;
  loaded = false;

  constructor(
    private router: Router ,
    private route: ActivatedRoute ,
    private registrationService: RegistrationService ,
    private adminService: AdminService ,
    private spinnerService: SpinnerService ,
    private storageService: StorageService ,
    private locationService: LocationService ,
    private analyticsSrv: AnalyticsService ,
    private googleSsoService: GoogleSsoService ,
    private geocodeService: GoogleGeocodingService,
    private facebookSsoService: FacebookSsoService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setLocation();
    this.createUserTags();
  }

  createUserTags(): void {
    this.adminService.createAccountTag(AccountTagKeys.LAST_PAGE , PageName.LOCATION)
      .pipe(
        takeWhile(() => this.active) ,
        switchMap((createdTag) => {
          const marketingInfo = this.storageService.getLocalStorageItem(StorageItemName.MARKETING_INFO);
          if (marketingInfo) {
            return this.adminService.createAccountTag(AccountTagKeys.MARKETING_INFO , marketingInfo);
          } else {
            return of(null);
          }
        })
      ).subscribe((createdTags) => console.log(createdTags , 'Created Tags'));
  }

  setLocation(): void {
    const {snapshot} = this.route;
    if (snapshot && snapshot.data && snapshot.data[ 'location' ]) {
      this.locationData = snapshot.data[ 'location' ];
      this.hasSavedLocation = true;
      this.loaded = true;
    } else if (this.storageService.getLocalStorageItem(StorageItemName.SSOTOKEN)) {
      if (this.storageService.getLocalStorageItem(StorageItemName.USER).source === SsoSource.GOOGLE) {
        this.getGoogleAccount();
      }
      if (this.storageService.getLocalStorageItem(StorageItemName.USER).source === SsoSource.FACEBOOK) {
        this.getFacebookPage();
      }
    } else {
        this.loaded = true;
    }
    this.checkLocationViewToDisplay();
  }

  checkLocationViewToDisplay(): void {
    this.locationProfileFormView = true;
    if (this.storageService.getLocalStorageItem(StorageItemName.USER) &&
        this.storageService.getLocalStorageItem(StorageItemName.USER)[ 'source' ] === SsoSource.GOOGLE) {
      this.locationProfileFormView = false;
      this.ssoSource = SsoSource.GOOGLE;
    }
  }

  getGoogleAccount(): void {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.storageService.getLocalStorageItem(StorageItemName.SSOTOKEN)}`
    });

    this.googleSsoService.getGoogleAccounts(headers)
      .pipe(
        takeWhile(() => this.active && !this.hasSavedLocation) ,
        spinnerPipe(this.spinnerService) ,
        map((accountsObject: any) => {
          const accountRoute = accountsObject.accounts[ 0 ].name;
          const ssoService = this.googleSsoService.getGoogleAccountLocations(accountRoute , headers);
          return ssoService;
        }),
        catchError((error) => {
          console.error(error);
          this.locationProfileFormView = true;
          return of(null);
        })
      ).subscribe((locationsObject: any) => {
        const location = (locationsObject && locationsObject[ 'locations' ] && locationsObject[ 'locations' ][ 0 ]) ? locationsObject[ 'locations' ][ 0 ] : null;
        if (location) {
          this.locationProfileFormView = false;
          this.setLocationFromBusinessLocation(location);
        } else {
          this.locationProfileFormView = true;
        }
        this.loaded = true;
    });
  }

  getFacebookPage() {
    this.facebookSsoService.getAccountsFromToken(this.storageService.getLocalStorageItem(StorageItemName.SSOTOKEN))
      .pipe(
        takeWhile(() => this.active),
        concatMap((accountResponse: AccountResponse) => {
          const { access_token: pageToken, name: pageName } = accountResponse.data[0];
          return zip(this.facebookSsoService.getLocationFromPageId(pageToken), of(pageName));
        })
      ).subscribe(([locationResponse, pageName]) => {
        if (locationResponse) {
          const location = {
            locationName: pageName,
            address: {
              regionCode: this.locationService.getCountryCode(locationResponse.location.country),
              addressLines: [locationResponse.location.street],
              locality: locationResponse.location.city,
              postalCode: locationResponse.location.zip,
              administrativeArea: locationResponse.location.state
            }
          };
          this.locationProfileFormView = false;
          this.setLocationFromBusinessLocation(location);
        } else {
          this.locationProfileFormView = true;
        }
        this.loaded = true;
      });
  }

  setLocationFromBusinessLocation(location: any): void {
    this.locationData = {
      companyName: location.locationName ,
      country: location.address.regionCode ,
      address: location.address.addressLines[ 0 ] ,
      city: location.address.locality ,
      zipOrPostalCode: location.address.postalCode ,
      stateOrProvince: this.locationService.getProvinceOrState(location.address.regionCode , location.address.administrativeArea)[ 'code' ]
    };

    this.getTimezone(this.locationData.city, this.locationData.stateOrProvince, this.locationData.country)
      .pipe(takeWhile(() => this.active))
      .subscribe((timezoneResponse: string) => this.locationData.timezone = timezoneResponse);
  }


  showLocationProfileForm(): void {
    this.locationProfileFormView = true;
    this.analyticsSrv.recordCustomClick('modify-address-location-page');
  }

  continue(): void {
    const currentUser = this.storageService.getLocalStorageItem(StorageItemName.USER);
    let formLocationData: ILocation;

    if (this.locationProfileFormView) {
      this.locationProfileForm.validate();
      if (this.locationProfileForm.isValid() === false) {
        return;
      }

      formLocationData = this.locationProfileForm.getFormattedLocationData();
    } else {
      formLocationData = this.locationData;
    }

    const companyDetails: RegistrationCompany = this.createCompanyDetails(formLocationData);

    let accountRequest: AccountPutRequest;
    let contactRequest = { contacts: [] };
    let siteRequest: any;

    this.createLocation(formLocationData)
      .pipe(
        takeWhile(() => this.active ),
        filter((requestLocation: Location) => requestLocation.timezone !== ''),
        switchMap((requestLocation: Location) => {
          accountRequest = {
            name: companyDetails.name,
            location: requestLocation,
          };

          siteRequest = {
            name: companyDetails.name ,
            location: requestLocation
          };

          contactRequest.contacts.push(
            {
              contactType: 'MAIN_CONTACT',
              email: currentUser.email,
              name: currentUser.name,
            }
          );

          return of(requestLocation);
        }),
        switchMap((requestLocation: Location) => {
          console.log('updateAccount -> ' , requestLocation);
          return this.adminService.updateAccount(currentUser.accountId, accountRequest);
        }),
        switchMap(() => {
          console.log('add Contact -->', contactRequest);
          return this.adminService.createContacts(currentUser.accountId, contactRequest);
        }),
        switchMap(() => {
          console.log('account updated');
          if (this.hasSavedLocation && currentUser.siteId) {
            console.log('updateSite -> ' , siteRequest);
            return this.adminService.updateSite(
              currentUser.accountId ,
              currentUser.siteId ,
              siteRequest
            );
          } else {
            const params = {
              accountId: currentUser.accountId ,
              body: siteRequest
            };
            console.log('createSite -> ' , params);
            return this.adminService.createSite(params);
          }
        }),
        switchMap((site: Site) => {
          console.log('Site created/updated' , site);
          this.storageService.setLocalStorageItem(StorageItemName.SITE , site);
         
          return this.adminService.updateUser(currentUser.uniqueUserId , {
            siteId: site.siteId
          });
        }),
        spinnerPipe(this.spinnerService)
      ).subscribe((user: User) => {
        
        this.registrationService.user = Object.assign(
          {} ,
          user ,
          {company: companyDetails} ,
          this.storageService.getLocalStorageItem(StorageItemName.USER)
        );
        this.storageService.setLocalStorageItem(StorageItemName.USER , this.registrationService.user);      
        this.router.navigate([ 'number' ] , {relativeTo: this.route.parent});
    });
  }


  createCompanyDetails(locationData: ILocation): RegistrationCompany {
    return Object.assign(new RegistrationCompany(), {
      name: locationData.companyName,
      country: locationData.country,
      address: locationData.address,
      address2: locationData.address2,
      city: locationData.city,
      stateOrProvince: locationData.stateOrProvince,
      zipOrPostalCode: locationData.zipOrPostalCode
    });
  }

  createLocation(location: ILocation): Observable<Location> {
    if (location && location.timezone) {
      return of({
          address: {
            city: location.city,
            stateOrProvince: location.stateOrProvince,
            street: location.address,
            street2: location.address2,
            country: location.country,
            zipCode: location.zipOrPostalCode
          },
          timezone: location.timezone
        } as Location);
    }

    return this.getTimezone(location.city , location.stateOrProvince , location.country)
      .pipe(
        takeWhile(() => this.active),
        flatMap((timezoneResponse: string) => {
          if ( !timezoneResponse && !this.locationData) {
            this.locationProfileForm.showTimeZoneDropdown();
          }

          return of({
            address: {
              city: location.city,
              stateOrProvince: location.stateOrProvince,
              street: location.address,
              street2: location.address2,
              country: location.country,
              zipCode: location.zipOrPostalCode
            },
            timezone: timezoneResponse ? timezoneResponse : this.locationData && this.locationData.timezone ? this.locationData.timezone : location.timezone
          } as Location);
        })
      );
  }

  getTimezone(cityName: string , provinceOrState: string , country: string): Observable<any> {
    return this.geocodeService.getLatLongFromLocation(cityName, provinceOrState, country)
      .pipe(
        takeWhile(() => this.active),
        flatMap((geocodeResponse: GeocodeResponseObject) => {
          if (geocodeResponse.status === 'OK') {
            const lat = geocodeResponse.results[ 0 ].geometry.location.lat;
            const lng = geocodeResponse.results[ 0 ].geometry.location.lng;
            return of(tzlookup(lat, lng));
          } else {
            return of('');
          }
        }),
        catchError((error) => {
          console.error(error);
          return of('');
        })
      );
  }
}
