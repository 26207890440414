import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService, UserDetails } from '../../../services/registration.service';
import { AdminService } from '../../../services/admin.service';
import { StorageItemName, StorageService } from '../../../services/storage-service.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { SpinnerService } from '../../../services/spinner.service';
import { catchError, distinctUntilChanged, switchMap, takeWhile, tap } from 'rxjs/operators';
import { BaseDataComponent } from '../shared/base-data-component';
import { spinnerPipe } from '../../../shared/utils/spinner-pipe.operator';
import { emailValidator } from '../../../shared/custom-validators';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { CONFLICT, FORBIDDEN, INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED, BAD_REQUEST } from 'http-status-codes';
import { AuthService } from '../../../services/auth.service';
import { Token, User } from '@mitel/cloudlink-sdk';
import { ToastService } from '../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Tag } from '@mitel/cloudlink-sdk/admin';
import { PageName } from '../../../shared/models/page-name.enum';
import { SsoProvider, SsoProviderResults } from '@mitel/cloudlink-sdk/authentication/authentication-service.g';
import { TextUtils } from '../../../shared/utils/text-utils';
import { MarketingCodes } from '../../../shared/models/marketing-codes.enum';
import { AnalyticsService } from '../../../services/analytics.service';
import { UserType } from '../../../shared/models/user-type-enum';
import { FormUtils } from '../../../shared/utils/form-utils';
import { RouteUtils } from '../../../shared/utils/route-utils';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('1s ease-in-out', style({ opacity: 1 }))
        ])
      ]
    )
  ]
})

export class SignupComponent extends BaseDataComponent implements OnInit, AfterViewInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private adminService: AdminService,
    private storageService: StorageService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private toastService: ToastService,
    private analyticsSrv: AnalyticsService,
    private translateService: TranslateService
  ) {
    super();
  }

  signUpForm: FormGroup;
  attemptedSubmit = false;
  signUp = true;
  googleIconSsoUrl = '';
  facebookIconSsoUrl = '';
  googleSignOnUrl = '';
  facebookSignOnUrl = '';
  authUrl = '';
  visiblePassword = false;
  eyeImageUrl = {
    showPasswordImage: '/assets/img/show-eye.svg',
    hidePasswordImage: '/assets/img/hide-eye.svg'
  };
  capsLockState: boolean;
  passwordFocus: boolean;
  capsLockWarningPlacement;

  @ViewChild('popOver') popOver: NgbPopover;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setCapsLockWarningPlacement();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.signUpForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', {
        validators: [Validators.required, emailValidator],
        updateOn: 'blur'
      }],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    const paymentTerm = this.route.snapshot.queryParamMap.get('term') || 'annual';
    this.storageService.setLocalStorageItem(StorageItemName.TERM, paymentTerm);
    console.log('payment term', paymentTerm);
    this.setCapsLockWarningPlacement();
    this.buildAuthUrl();
    this.getSsoProviders();

    this.route.queryParams
      .pipe(takeWhile(() => this.active))
      .subscribe((params) => {

        this.saveMarketingCodes(params);

        if (params['code']) {
          const code = params['code'];
          this.storageService.setLocalStorageItem(StorageItemName.USERCODE, code);
          const loginParams = {
            code: {
              code: code,
              clientId: environment.clientId
            }
          };
          this.doSsoLogin(loginParams);
        }
        if (params['login']) {
          this.setSignUp(false);
        }
      });


    // error: "access_denied"
    // errorCode: "4090"
    // error_description: "User already exists"
  }

  ngAfterViewInit(): void {

    this.signUpForm.controls['email'].valueChanges
      .pipe(
        takeWhile(() => this.active),
        distinctUntilChanged())
      .subscribe((email) => {
        this.checkIfUserExists(email);
      });
  }

  buildAuthUrl(): void {

    const adminUrl = RouteUtils.getAdminURL(location.hostname);

    const queryString = TextUtils.toQueryString({
      client_id: environment.clientId,
      response_type: 'code',
      redirect_uri: adminUrl,
      app_name: 'TalkifyAdmin'
    });

    this.authUrl = `${environment.authUrl}?${queryString}`;
  }

  getSsoProviders(): void {
    this.authService.getSsoProviders({ clientId: environment.clientId })
      .pipe(takeWhile(() => this.active))
      .subscribe((ssoProviderResponse: SsoProviderResults) => {
        if (ssoProviderResponse && Object.keys(ssoProviderResponse).length) {
          const googleSso: SsoProvider = ssoProviderResponse.providers.find((providers => providers.providerId === 'google'));
          const facebookSso: SsoProvider = ssoProviderResponse.providers.find((providers => providers.providerId === 'facebook'));
          this.googleSignOnUrl = googleSso.signonUrl;
          this.facebookSignOnUrl = facebookSso.signonUrl;
          const redirectUri = window.location.href.endsWith('signup') ? window.location.href : window.location.href.split('?')[0];
          const queryString = TextUtils.toQueryString({
            client_id: environment.clientId,
            redirect_uri: redirectUri,
            action: 'sign-up'
          });
          this.googleIconSsoUrl = `${this.googleSignOnUrl}?${queryString}`;
          this.facebookIconSsoUrl = `${this.facebookSignOnUrl}?${queryString}`;
        }
      });

  }

  saveMarketingCodes(params): void {

    if (Object.keys(params).length === 0) { return; }

    const marketingCodeValues = Object.values(MarketingCodes);
    const queryParamsKeys = Object.keys(params);

    queryParamsKeys.forEach((key: string) => {
      const marketingCodeLocalStorage = this.storageService.getLocalStorageItem(StorageItemName.MARKETING_INFO) ? this.storageService.getLocalStorageItem(StorageItemName.MARKETING_INFO) : {};

      if (marketingCodeValues.indexOf(key) > -1) {
        marketingCodeLocalStorage[key] = params[key];
      }
      if (Object.keys(marketingCodeLocalStorage).length !== 0) {
        this.storageService.setLocalStorageItem(StorageItemName.MARKETING_INFO, marketingCodeLocalStorage);
      }
    });
  }

  doSsoLogin(params): void {

    this.storageService.clearLocalStorage([StorageItemName.SSOTOKEN, StorageItemName.MARKETING_INFO, StorageItemName.TERM])
      .pipe(
        takeWhile(() => this.active),
        switchMap(() => this.authService.doSsoLogin(params)),
        switchMap((user: User) => {
          console.log('user', user);
          this.storageService.setLocalStorageItem(StorageItemName.USER, user);
          const tagParams = {
            accountId: user.accountId,
            tagId: 'lastViewedOnboardingPage'
          };
          return this.adminService.getAccountTag(tagParams);
        }),
        spinnerPipe(this.spinnerService)
      )
      .subscribe((pageName: Tag) => {
        if (pageName) {
          if (pageName === PageName.DASHBOARD) {
            this.authService.routeToDashboard();
          } else {
            this.router.navigate([pageName], { relativeTo: this.route.parent });
          }
        } else {
          // at this point the assumption ois the user is signing up for the first time, so send out the welcome email
          const user = this.storageService.getLocalStorageItem(StorageItemName.USER);
          const welcomeEmailParams = {
            accountId: user.accountId,
            userId: user.uniqueUserId,
            body: {}
          };
          this.adminService.sendWelcomeToUserByAccountId(welcomeEmailParams, UserType.admin)
            .subscribe(() => {
              this.router.navigate(['location'], { relativeTo: this.route.parent });
            });
        }
      });
  }


  checkIfUserExists(email): void {

    email = this.signUpForm.controls['email'].value ? this.signUpForm.controls['email'].value.trim() : '';

    if (!email || this.signUpForm.controls['email'].invalid) {
      return;
    }

    const params = {
      userId: email.toLowerCase(),
      options: {
        headers: {
          'x-mitel-application-id': environment.clientId
        }
      }
    };
    /*
      403 === non galaxy application --> signup = true
      404 === email doesn't exist --> signup = true
      409 === more than one email exists --> signup = false;
      204 === email in system --> signup = false
      ****ANY**** successful response returned here is going to set --> signup = false;
    */

    this.adminService.userExists(params)
      .pipe(
        catchError((err) => {
          if (err.statusCode === CONFLICT) {
            this.setSignUp(false);
          } else if (err.statusCode === NOT_FOUND || err.statusCode === FORBIDDEN) {
            this.setSignUp(true);
          } else if (err.statusCode === INTERNAL_SERVER_ERROR) {
            this.toastService.showToast(this.translateService.instant('errors.global.serverError'));
          }
          return of(null);
        })
      )
      .subscribe((response) => {
        if (response) {
          this.setSignUp(false);
        }
      });
  }

  submitForm(): void {
    this.attemptedSubmit = true;
    FormUtils.markFormFieldsAsDirty(this.signUpForm);
    if (!this.signUpForm.valid) { return; }

    const formValues = this.signUpForm.value;

    if (this.signUp) {
      const newUser = Object.assign(new UserDetails(), {
        name: formValues.name,
        email: formValues.email.toLowerCase(),
        password: formValues.password
      });

      this.storageService.clearLocalStorage([StorageItemName.MARKETING_INFO, StorageItemName.TERM])
        .pipe(
          takeWhile(() => this.active),
          switchMap(() => this.registrationService.registerUser(newUser)),
          spinnerPipe(this.spinnerService)
        )
        .subscribe((result) => {
          console.log('signup.component.submitForm().. complete', result);
          // TODO eventually will have to remove password from localStorage
          // Record signup to analytics
          this.analyticsSrv.recordEvent('Sign up', { page: 'getStarted/signup' });
          this.storageService.setLocalStorageItem(StorageItemName.USER, newUser);
          this.router.navigate(['verification'], { relativeTo: this.route.parent });
        }, (error) => {
          console.error('signup.component.submitForm().. failure', error);

          let errorMsg = this.translateService.instant('errors.signUp.failureUnknown');
          // todo: capture any known conditions... right now we only have 1 and for others we show a generic error.
          // switch to a switch statement once we have a list
          if (error.statusCode === BAD_REQUEST && error.body.name === 'InvalidEmailAddress') {
            // there are certain types of error messages that we do NOT support. This is the case with group emails like info@company.com
            errorMsg = this.translateService.instant('errors.signUp.failureEmailRestricted');
            this.signUpForm.get('email').markAsTouched();
            this.signUpForm.get('email').setErrors({ noMatch: true });
            this.toastService.showToast(errorMsg, 25000);
          }
          else {
            this.toastService.showToast(errorMsg, 25000);
          }
        });
    } else {

      const params = {
        user: {
          username: formValues.email,
          password: formValues.password,
          clientId: environment.clientId
        }
      };

      this.storageService.clearLocalStorage([StorageItemName.MARKETING_INFO, StorageItemName.TERM])
        .pipe(
          takeWhile(() => this.active),
          spinnerPipe(this.spinnerService),
          switchMap(() => this.authService.login(params)),
          switchMap((token: Token) => this.adminService.getUser({ userId: 'me' })),
          tap((user: User) => this.storageService.setLocalStorageItem(StorageItemName.USER, { ...user, password: formValues.password })),
          switchMap((user: User) => {
            if (user) {
              return this.saveUserAndGetLastViewedOnboardingPageTag(user);
            } else {
              return of(null);
            }
          }),
          catchError((error) => {
            switch (error.statusCode) {
              case CONFLICT:
                this.toastService.showToast('Multiple users found, feature coming soon');
                break;
              case UNAUTHORIZED:
                this.signUpForm.get('password').markAsTouched();
                this.signUpForm.get('password').setErrors({ noMatch: true });
                break;
            }
            return of(null);
          })
        ).subscribe((pageName: Tag) => {
          if (pageName && pageName === PageName.DASHBOARD) {
            this.authService.routeToDashboard();
          } else if (pageName) {
            this.router.navigate([pageName], { relativeTo: this.route.parent });
          }
        });
    }
  }

  setSignUp(value: boolean, resetFields?: boolean): void {
    if (value) {
      this.signUp = true;
      this.signUpForm.get('name').setValidators([Validators.required]);
      if (resetFields) {
        this.signUpForm.reset();
      }
    } else {
      this.signUp = false;
      this.signUpForm.get('name').clearValidators();
    }
    this.signUpForm.get('name').updateValueAndValidity();
  }

  saveUserAndGetLastViewedOnboardingPageTag(user: User): Observable<Tag> {
    this.storageService.setLocalStorageItem(StorageItemName.USER, user);
    const tagParams = {
      accountId: user.accountId,
      tagId: 'lastViewedOnboardingPage'
    };
    return this.adminService.getAccountTag(tagParams);
  }

  togglePasswordVisibility(): void {
    this.visiblePassword = !this.visiblePassword;
  }

  setCapsLockState(newState: boolean) {
    if (this.capsLockState !== newState) {
      this.capsLockState = newState;
      if (this.capsLockState && this.passwordFocus) {
        this.showCapsLockWarning();
      } else if (this.popOver.isOpen()) {
        this.popOver.close();
      }
    }
  }

  setPasswordFocus(value: boolean): void {
    this.passwordFocus = value;
    if (this.capsLockState && this.passwordFocus && !this.popOver.isOpen()) {
      this.showCapsLockWarning();
    } else if (!this.passwordFocus && this.popOver.isOpen()) {
      this.popOver.close();
    }
  }

  showCapsLockWarning(): void {
    this.popOver.open();
  }

  setCapsLockWarningPlacement(): void {
    this.capsLockWarningPlacement = window.innerWidth > 690 ? 'right' : 'top';
  }
}
