import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { InvalidMessageDirective } from './invalid-message.directive';
import { BaseDataComponent } from '../../modules/get-started/shared/base-data-component';
import { takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[invalidType]'
})

export class InvalidTypeDirective extends BaseDataComponent implements OnInit {

  @Input('invalidType') type: string;
  private hasView = false;

  constructor(
    private invalidMessage: InvalidMessageDirective,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.invalidMessage.controlValue$
      .pipe(takeWhile(() => this.active))
      .subscribe((val) => {
        this.setVisible();
      });
  }

  private setVisible() {
    if ( this.invalidMessage.matchErrors(this.type) && !this.hasView ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if ( this.hasView && !this.invalidMessage.matchErrors(this.type)) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

}
