import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDataComponent } from '../shared/base-data-component';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { TextUtils } from '../../../shared/utils/text-utils';
import { FormControl } from '@angular/forms';
import { CarrierService } from '../../../services/carrier.service';
import { spinnerPipe } from '../../../shared/utils/spinner-pipe.operator';
import { SpinnerService } from '../../../services/spinner.service';
import { StorageItemName, StorageService } from '../../../services/storage-service.service';
import { Site } from '@mitel/cloudlink-sdk';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { of } from 'rxjs';
import { AdminService } from '../../../services/admin.service';
import { PageName } from '../../../shared/models/page-name.enum';
import { AccountTagKeys } from '../../../shared/models/user-tag-keys.enum';


@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent extends BaseDataComponent implements OnInit, AfterViewInit {
  loading = true;
  selectedNumber: string;
  showNumberList = false;
  numbers: string[] = [];
  filteredNumbers: string[] = [];
  searchedNumberFormControl: FormControl = new FormControl('');
  searcherNumber: string;
  showTollFreeMessage = false;
  wordToNumberArray: string[] = [];
  wordToNumberValue = '';
  charNumberMap = ['', '', 'abc', 'def', 'ghi', 'jkl', 'mno', 'pqrs', 'tuv', 'wxyz'];
  tollFree = false;
  sliceStartIndex = 0;
  userSite: Site;
  maxNumbers = 30;
  maxDisplayNumbers = 10;
  showSearch = false;
  searchEnabled = false;
  areaCodes: any[] = [];
  isFilteringAreaCode = false;
  areaCodeFilterValue: string;
  filterAreaCodeSelected = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private carrierService: CarrierService,
    private spinnerService: SpinnerService,
    private storageService: StorageService,
    private adminService: AdminService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.userSite = this.storageService.getLocalStorageItem(StorageItemName.SITE);
    this.getLocalNumbers();
    this.selectedNumber = this.storageService.getLocalStorageItem(StorageItemName.BUSINESS_NUMBER);
    console.log('this.selectedNumber', this.selectedNumber);
    this.adminService.createAccountTag(AccountTagKeys.LAST_PAGE, PageName.NUMBER)
      .pipe(
        takeWhile(() => this.active),
      ).subscribe((createdTag) => console.log(createdTag, 'account tag created'));
  }


  ngAfterViewInit(): void {
    this.searchedNumberFormControl.valueChanges
      .pipe(
        takeWhile(() => this.active),
        map((inputValue: string) => {
          const filterValue = inputValue.trim();
          const isAlpha = TextUtils.isAlpha(filterValue);
          if (isAlpha) {
            this.areaCodeFilterValue = filterValue;
          } else {
            this.areaCodeFilterValue = '';
            if (filterValue && filterValue.length >= 3) {
              this.areaCodeFilterValue = filterValue;
            }
          }
          this.isFilteringAreaCode = !!this.areaCodeFilterValue;
          return inputValue;
        }),
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(() => {
          if (this.areaCodeFilterValue && !this.filterAreaCodeSelected) {
            const isAlpha = TextUtils.isAlpha(this.areaCodeFilterValue);
            if (isAlpha) {
              console.log('search rate center by city');
              return this.carrierService.getRateCenters(this.userSite, this.areaCodeFilterValue)
                .pipe(catchError(() => of([])));
            } else {
              console.log('search rate center by area code');
              return this.carrierService.getRateCenters(this.userSite, null, this.areaCodeFilterValue)
                .pipe(catchError(() => of([])));
            }
          }
          return of([]);
        })
      )
      .subscribe((areaCodes: any[]) => {
        this.isFilteringAreaCode = false;
        if (areaCodes) {
          console.log('areaCodes', areaCodes);
          this.areaCodes = areaCodes;
        } else {
          this.areaCodes = [];
        }

        if (this.areaCodes.length === 0) {
          console.warn('unable to get area-codes via rate center... using broader search', areaCodes);
          this.handleNumberSearch();
        }

      });
  }

  handleNumberSearch(): void {
    this.searcherNumber = this.searchedNumberFormControl.value.trim();
    this.getNumbersBySearch();
  }

  getNumbersBySearch(): void {
    if (this.searcherNumber) {
      console.log('handleSearch -> ', this.searcherNumber);

      const isAlpha = TextUtils.isAlpha(this.searcherNumber);

      if (isAlpha) {
        this.carrierService.searchNumbersByCity(this.userSite, this.searcherNumber, this.maxNumbers)
          .pipe(
            takeWhile(() => this.active),
            spinnerPipe(this.spinnerService))
          .subscribe((numbers: string[]) => {
            this.setUpResults(numbers);
            if (numbers.length) {
              this.searchEnabled = false;
            }
          },
            (err: any) => this.setUpResults([]));
      } else {
        this.carrierService.searchNumbersByAreaCode(this.userSite, this.searcherNumber, this.maxNumbers)
          .pipe(
            takeWhile(() => this.active),
            spinnerPipe(this.spinnerService))
          .subscribe((numbers: string[]) => {
            this.setUpResults(numbers);
            if (numbers.length) {
              this.searchEnabled = false;
            }
          },
            (err: any) => this.setUpResults([]));
      }
    }
  }

  filterNumbers(phoneNumber: string, numberOfChars: number = 0, alphaValue?: string): void {

    this.filteredNumbers = this.filteredNumbers
      .map((value: string) => value.replace(/(?!\w|\s)./g, ''))
      .filter((value: string) => value.slice(numberOfChars).indexOf(phoneNumber) > -1)
      .map((value: string) => {
        if (alphaValue) {
          this.wordToNumberArray.push(value.slice(numberOfChars));
          const regex = `${value.slice(numberOfChars)}`;
          value = value.replace(regex, alphaValue.toLocaleUpperCase());
        }
        return TextUtils.normalizePhoneNumber(value);
      });

    if (alphaValue) {
      this.updateWordToNumber();
    }
  }

  updateWordToNumber(): void {
    setTimeout(() => {
      this.wordToNumberValue = this.wordToNumberArray[this.filteredNumbers.findIndex((val: string) => val === this.selectedNumber)];
    });

  }

  fillArray(arrayToFill: string[]): void {
    if (arrayToFill.length === this.maxDisplayNumbers) {
      return;
    } else {
      for (let index = arrayToFill.length; index < this.maxDisplayNumbers; index++) {
        arrayToFill[index] = 'not available';
      }
    }
  }

  toggleNumberList(): void {
    this.showNumberList = !this.showNumberList;
    if (this.showNumberList) {
      this.showSearch = true;
    }
  }


  getLocalNumbers(): void {
    this.resetNumbers();
    // this.showTollFreeMessage = true;
    this.tollFree = false;
    this.searchedNumberFormControl.reset(null, { emitEvent: false });
    this.getNumbers();
  }

  getTollFreeNumbers(): void {
    this.resetNumbers();
    this.tollFree = true;
    this.getNumbers();
    this.showTollFreeNumbersMessage();
  }

  showTollFreeNumbersMessage(): void {
    this.showTollFreeMessage = false;
  }

  getNumbers(): void {
    this.carrierService.getNumbersFromSite(this.userSite, this.maxNumbers, this.tollFree)
      .pipe(
        takeWhile(() => this.active),
        spinnerPipe(this.spinnerService))
      .subscribe((numbers: string[]) => {
        this.setUpResults(numbers);
      },
        (err: any) => {
          this.setUpResults([]);
        });
  }

  refreshNumbers(): void {
    if ((this.numbers.length - this.sliceStartIndex) <= this.maxDisplayNumbers) {
      if (this.searchEnabled && this.searchedNumberFormControl.value) {
        this.getNumbersBySearch();
      } else {
        this.getNumbers();
      }
      return;
    }
    this.createdFilteredList(true);
  }

  toggleSearchEnabled(): void {
    this.searchEnabled = !this.searchEnabled;
    if (this.searchEnabled) {
      this.searchedNumberFormControl.setValue('');
    }
  }

  createdFilteredList(updateSliceIndex: boolean): void {
    if (updateSliceIndex) {
      this.sliceStartIndex += this.maxDisplayNumbers;
    }

    this.filteredNumbers = this.numbers.slice(this.sliceStartIndex, this.sliceStartIndex + this.maxDisplayNumbers);
    if (!this.selectedNumber) {
      this.selectedNumber = this.filteredNumbers[0];
    }
  }

  continue(): void {
    if (this.selectedNumber) {
      this.storageService.setLocalStorageItem(StorageItemName.BUSINESS_NUMBER, this.selectedNumber);
      this.goToNextStep();
    }
  }

  resetNumbers(): void {
    this.filteredNumbers.length = 0;
    this.numbers = this.filteredNumbers;
    this.selectedNumber = '';
  }

  setUpResults(numbers: string[]) {
    console.log('setUpResults -> ', numbers);
    this.numbers = numbers;
    this.sliceStartIndex = 0;

    if (!this.numbers || !this.numbers.length) {
      this.showSearch = true;
      this.searchEnabled = true;
    }

    if (this.searchEnabled) {
      this.showNumberList = true;
    }

    this.createdFilteredList(false);
    this.loading = false;
  }

  skipStep(): void {
    this.storageService.removeLocalStorageItem(StorageItemName.BUSINESS_NUMBER);
    this.goToNextStep();
  }

  goToNextStep(): void {
    this.router.navigate(['invite'], { relativeTo: this.route.parent });
  }

  selectAreaCodeFilter(event: MatAutocompleteSelectedEvent) {
    this.filterAreaCodeSelected = true;
    this.areaCodeFilterValue = '';
    this.isFilteringAreaCode = false;

    this.searcherNumber = event.option.value;
    this.getNumbersBySearch();

    setTimeout(() => {
      this.filterAreaCodeSelected = false;
    }, 510);
  }

}
