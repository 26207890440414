import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvalidMessageDirective } from './directives/invalid-message.directive';
import { InvalidTypeDirective } from './directives/invalid-type.directive';
import { SpinnerComponent } from '../modules/get-started/shared/spinner/spinner.component';
import { BrowserModule } from '@angular/platform-browser';

import {
  MatAutocompleteModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule
} from '@angular/material';
import { FooterNavigationComponent } from './components/footer-navigation/footer-navigation.component';
import { LocationProfileFormComponent } from './components/location-profile-form/location-profile-form.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { SelectedLocationComponent } from './components/selected-location/selected-location.component';
import { RegisterInputDirective } from './directives/register-input.directive';

const modules = [
  CommonModule,
  TranslateModule,
  AngularFontAwesomeModule,
  FormsModule,
  ReactiveFormsModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatOptionModule,
  MatInputModule,
  MatTooltipModule,
  MatSelectModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  BrowserModule
];

const components = [
  FooterNavigationComponent,
  LocationProfileFormComponent,
  ErrorPageComponent,
  SelectedLocationComponent
];

const directives = [
  InvalidMessageDirective,
  InvalidTypeDirective,
  RegisterInputDirective
];

const entryComponents = [
  SpinnerComponent
];

@NgModule({
  imports: [
    ...modules
  ],
  declarations: [
    ...directives,
    ...components
  ],
  entryComponents: [
    ...entryComponents
  ],
  exports: [
    ...components,
    ...directives,
    ...modules
  ]
})
export class SharedModule {
}
